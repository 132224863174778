<template>
	<a-modal :visible="visible" @ok="ok" @cancel="cancel" :destroyOnClose="true" :maskClosable="false" title="其他设置" :width="600">
		<a-form-model :model="formData" layout="horizontal" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }" ref="otherSetting">
			<a-form-model-item label="账号备注">
				<a-textarea v-model="formData.remark" placeholder="请输入备注" :maxLength="300" :auto-size="{ minRows: 5, maxRows: 5 }"> </a-textarea>
			</a-form-model-item>
			<a-form-model-item label="任务超时">
				<a-input-number :min="0" :max="3600" v-model="formData.timeout" />
				<span>秒未执行完毕,自动结束当前任务,设置0即禁用超时</span>
			</a-form-model-item>
			<a-form-model-item label="启动参数">
				<a-textarea v-model="formData.params" placeholder="请输入启动参数" :auto-size="{ minRows: 5, maxRows: 5 }"> </a-textarea>
			</a-form-model-item>
		</a-form-model>
	</a-modal>
</template>

<script>
export default {
	props: {
		visible: {
			type: Boolean,
			default: false,
		},
		config: {
			type: Object,
			default: () => {},
		},
	},
	components: {},
	name: "OtherSetting",
	data() {
		return {
			formData: {
				remark: "",
			},
		};
	},
	watch: {
		visible: {
			handler(newVal) {
				if (newVal) {
					this.formData = JSON.parse(JSON.stringify(this.config));
				}
			},
		},
	},
	methods: {
		ok() {
			this.$refs.otherSetting.validate(result => {
				if (result) {
					this.$emit("ok", this.formData);
					this.cancel();
				}
			});
		},
		cancel() {
			this.$emit("update:visible", false);
		},
	},
};
</script>
<style lang="less" scoped>
::v-deep .ant-modal {
	.ant-modal-content {
		.ant-modal-title {
			font-weight: bold;
			font-size: 18px;
		}
	}
}
</style>
