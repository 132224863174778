<template>
	<a-modal :visible="visible" :title="operate_name[target.type]" :width="750" @cancel="cancel" @ok="ok" :destroyOnClose="true" :maskClosable="false">
		<a-alert message="获取页面上当前获得焦点的元素" type="info" v-if="isVisible(['getActiveElement'])" style="margin-bottom: 20px" />
		<a-alert message="从变量值为数组或对象里随机提取一个下标值" type="info" v-if="isVisible(['randomGet'])" style="margin-bottom: 20px" />
		<a-alert message="以数组或对象循环执行" type="info" v-if="isVisible(['forLists'])" style="margin-bottom: 20px" />
		<a-alert type="info" v-if="isVisible(['useExcel'])" style="margin-bottom: 20px">
			<template #message>
				<ul style="font-size: 12px; line-height: 24px">
					<li>1、根据表头的名称生成对应列的变量名</li>
					<li>2、表头只能是字母、数字、下划线，且不能为数字开头命名</li>
					<li>3、表头名为serial_number，可指定对应行内容为该浏览器环境使用的素材内容</li>
					<li>4、整个Excel的数据以数组形式到变量，如[{key1:value, key2:value...},{key1:value, key2:value...}...]</li>
				</ul>
			</template>
		</a-alert>
		<a-alert message="执行时实时读取文本内容，以换行符为分隔成数组数据" type="info" v-if="isVisible(['importText'])" style="margin-bottom: 20px" />
		<a-form-model :model="cur_config" layout="horizontal" :label-col="{ span: 4 }" :wrapper-col="{ span: 19 }" ref="configForm" :rules="rules">
			<a-form-model-item label="标题" v-if="isVisible(['switchPage'])">
				<a-row :gutter="10">
					<a-col :span="12">
						<a-select v-model="cur_config.type">
							<a-select-option v-for="(item, index) in tab_type_options" :key="index" :value="item.value">
								{{ item.label }}
							</a-select-option>
						</a-select>
					</a-col>
					<a-col :span="12">
						<a-select v-model="cur_config.relation">
							<a-select-option v-for="(item, index) in relation_options" :key="index" :value="item.value">
								{{ item.label }}
							</a-select-option>
						</a-select>
					</a-col>
				</a-row>
			</a-form-model-item>
			<a-form-model-item label="标签信息" prop="content" v-if="isVisible(['switchPage'])">
				<a-dropdown :trigger="['click']" overlayClassName="selectVariableDropDown">
					<div class="wrap">
						<a-row :gutter="10">
							<a-col :span="20" @click.stop="e => e.preventDefault()">
								<a-input v-model="cur_config.content" />
							</a-col>
							<a-col :span="4">
								<span style="color: #3860f4; cursor: pointer">使用变量</span>
							</a-col>
						</a-row>
					</div>
					<a-menu slot="overlay">
						<a-menu-item
							v-for="(item, index) in env_variables.concat(allVariables)"
							:key="index"
							@click="cur_config.content += '${' + item.value + '}'"
							style="display: flex; align-items: center; justify-content: space-between">
							<b>{{ item.value }}</b>
							<span>{{ item.label }}</span>
						</a-menu-item>
					</a-menu>
				</a-dropdown>
			</a-form-model-item>
			<a-form-model-item label="访问URL" prop="url" v-if="isVisible(['gotoUrl'])">
				<a-dropdown :trigger="['click']" overlayClassName="selectVariableDropDown">
					<div class="wrap">
						<a-row :gutter="10">
							<a-col :span="20" @click.stop="e => e.preventDefault()">
								<a-input v-model="cur_config.url" />
							</a-col>
							<a-col :span="4">
								<span style="color: #3860f4; cursor: pointer">使用变量</span>
							</a-col>
						</a-row>
					</div>
					<a-menu slot="overlay">
						<a-menu-item
							v-for="(item, index) in env_variables.concat(allVariables)"
							:key="index"
							@click="cur_config.url += '${' + item.value + '}'"
							style="display: flex; align-items: center; justify-content: space-between">
							<b>{{ item.value }}</b>
							<span>{{ item.label }}</span>
						</a-menu-item>
					</a-menu>
				</a-dropdown>
			</a-form-model-item>
			<a-form-model-item label="截图名称" v-if="isVisible(['screenshotPage'])">
				<a-input placeholder="默认：任务ID+用户ID+时间戳" v-model="cur_config.name"></a-input>
			</a-form-model-item>
			<a-form-model-item label="截全屏" v-if="isVisible(['screenshotPage'])">
				<a-switch default-checked @change="switchChange" />
			</a-form-model-item>
			<a-form-model-item label="图片格式" v-if="isVisible(['screenshotPage'])">
				<a-select v-model="cur_config.format" style="width: 120px">
					<a-select-option value="png"> png </a-select-option>
					<a-select-option value="jpeg"> jpeg </a-select-option>
				</a-select>
			</a-form-model-item>
			<a-form-model-item label="图片质量" v-if="isVisible(['screenshotPage']) && cur_config.format === 'jpeg'">
				<a-row :gutter="20">
					<a-col :span="12">
						<a-slider :marks="marks" v-model="cur_config.quality" />
					</a-col>
				</a-row>
			</a-form-model-item>
			<a-form-model-item
				label=""
				v-if="isVisible(['passingElement', 'selectElement', 'focusElement', 'click', 'inputContent', 'uploadAttachment', 'getElement'])">
				<a-row>
					<a-col :span="5"> </a-col>
					<a-col :span="18">
						<a-radio-group v-model="cur_config.selectorType" button-style="solid">
							<a-radio-button value="selector"> 元素选择器 </a-radio-button>
							<a-radio-button value="element"> 存储的元素对象(1) </a-radio-button>
						</a-radio-group>
					</a-col>
				</a-row>
			</a-form-model-item>
			<a-form-model-item label="" v-if="isVisible(['forElements'])" style="margin-bottom: 0">
				<a-row>
					<a-col :span="5"> </a-col>
					<a-col :span="18">获取元素选择器在页面上所有相似元素的循环执行</a-col>
				</a-row>
			</a-form-model-item>
			<a-form-model-item label="选择器" prop="selector" v-if="cur_config.selectorType === 'selector' || isVisible(['waitForSelector', 'forElements'])">
				<a-input v-model="cur_config.selector" placeholder="请输入元素选择器，例如 #email input" />
			</a-form-model-item>
			<a-form-model-item label="元素顺序" prop="serial" v-if="cur_config.selectorType === 'selector' || isVisible(['waitForSelector'])">
				<a-row :gutter="10">
					<a-col :span="12" v-if="isVisible(['passingElement', 'selectElement', 'focusElement', 'click', , 'uploadAttachment', 'getElement'])">
						<a-select v-model="cur_config.serialType">
							<a-select-option value="fixedValue"> 固定值 </a-select-option>
							<a-select-option value="randomInterval"> 区间随机 </a-select-option>
						</a-select>
					</a-col>
					<a-col :span="12">
						<a-input-number
							v-model="cur_config.serial"
							:min="1"
							:max="50000"
							:step="1"
							:parser="value => value.replaceAll('.', '')"
							v-if="isVisible(['waitForSelector', 'inputContent']) || cur_config.serialType === 'fixedValue'" />
						<div class="range" v-else>
							<a-input-number v-model="cur_config.serialMin" :min="1" :max="50000" :step="1" :parser="value => value.replaceAll('.', '')" />
							<span> - </span>
							<a-input-number v-model="cur_config.serialMax" :min="0" :max="50000" :step="1" :parser="value => value.replaceAll('.', '')" />
						</div>
					</a-col>
				</a-row>
			</a-form-model-item>
			<a-form-model-item label="元素对象" prop="element" v-if="cur_config.selectorType === 'element'">
				<a-select v-model="cur_config.element" option-label-prop="value">
					<a-select-option v-for="(item, index) in elementObject" :key="index" :value="item.value">
						<div style="display: flex; align-items: center; justify-content: space-between">
							<b>{{ item.value }}</b>
							<span>{{ item.label }}</span>
						</div>
					</a-select-option>
				</a-select>
			</a-form-model-item>
			<a-form-model-item label="选择的值" prop="value" v-if="isVisible(['selectElement'])">
				<a-dropdown :trigger="['click']" overlayClassName="selectVariableDropDown">
					<div class="wrap">
						<a-row :gutter="10">
							<a-col :span="20" @click.stop="e => e.preventDefault()">
								<a-input v-model="cur_config.value" />
							</a-col>
							<a-col :span="4">
								<span style="color: #3860f4; cursor: pointer">使用变量</span>
							</a-col>
						</a-row>
					</div>
					<a-menu slot="overlay">
						<a-menu-item
							v-for="(item, index) in env_variables.concat(allVariables)"
							:key="index"
							@click="cur_config.value += '${' + item.value + '}'"
							style="display: flex; align-items: center; justify-content: space-between">
							<b>{{ item.value }}</b>
							<span>{{ item.label }}</span>
						</a-menu-item>
					</a-menu>
				</a-dropdown>
			</a-form-model-item>
			<a-form-model-item label="点击方式" v-if="isVisible(['click', 'mouseClick'])">
				<a-row :gutter="10">
					<a-col :span="12">
						<a-select v-model="cur_config.button">
							<a-select-option v-for="(item, index) in click_button" :key="index" :value="item.value">
								{{ item.label }}
							</a-select-option>
						</a-select>
					</a-col>
					<a-col :span="12">
						<a-select v-model="cur_config.type">
							<a-select-option v-for="(item, index) in click_type" :key="index" :value="item.value">
								{{ item.label }}
							</a-select-option>
						</a-select>
					</a-col>
				</a-row>
			</a-form-model-item>
			<a-form-model-item label="内容" prop="randomContent" v-if="isVisible(['inputContent'])">
				<a-radio-group v-model="cur_config.isRandom" button-style="solid" size="small">
					<a-radio-button value="0"> 顺序选取 </a-radio-button>
					<a-radio-button value="1"> 随机选取 </a-radio-button>
				</a-radio-group>
				<a-dropdown :trigger="['click']" overlayClassName="selectVariableDropDown">
					<div class="wrap">
						<a-row :gutter="10">
							<a-col :span="20" @click.stop="e => e.preventDefault()">
								<a-textarea
									v-model="cur_config.randomContent"
									placeholder="单个内容请在一行输入；
多个内容请换行输入，会选取其中一个内容，示例：
内容一
内容二
内容最多 50 行，每行最多 500 个字符"
									:auto-size="{ minRows: 5, maxRows: 5 }" />
							</a-col>
							<a-col :span="4">
								<span style="color: #3860f4; cursor: pointer">使用变量</span>
							</a-col>
						</a-row>
					</div>
					<a-menu slot="overlay">
						<a-menu-item
							v-for="(item, index) in env_variables.concat(allVariables)"
							:key="index"
							@click="cur_config.content += '${' + item.value + '}'"
							style="display: flex; align-items: center; justify-content: space-between">
							<b>{{ item.value }}</b>
							<span>{{ item.label }}</span>
						</a-menu-item>
					</a-menu>
				</a-dropdown>
			</a-form-model-item>
			<a-form-model-item label="输入间隔时间" v-if="isVisible(['inputContent'])">
				<a-input-number
					v-model="cur_config.intervals"
					:min="0"
					:max="3600000"
					:step="100"
					style="width: 130px"
					:parser="value => value.replaceAll('.', '')" />
				<span>&nbsp;&nbsp;&nbsp;毫秒</span>
			</a-form-model-item>
			<a-form-model-item label="滚动至" prop="distance" v-if="isVisible(['scrollPage'])">
				<a-row :gutter="10">
					<a-col :span="12">
						<a-select v-model="cur_config.scrollType">
							<a-select-option value="position"> 位置 </a-select-option>
							<a-select-option value="pixel"> 像素 </a-select-option>
						</a-select>
					</a-col>
					<a-col :span="12" v-if="cur_config.scrollType === 'position'">
						<a-select v-model="cur_config.position">
							<a-select-option v-for="(item, index) in scroll_position" :key="index" :value="item.value">
								{{ item.label }}
							</a-select-option>
						</a-select>
					</a-col>
					<a-col :span="12" v-else>
						<a-input-number v-model="cur_config.distance" :min="1" :max="100000" :step="1" :parser="value => value.replaceAll('.', '')" />
						<span>&nbsp;&nbsp;&nbsp;像素</span>
					</a-col>
				</a-row>
			</a-form-model-item>
			<a-form-model-item label="附件" prop="url" v-if="isVisible(['uploadAttachment'])">
				<a-radio-group v-model="cur_config.type" size="small" button-style="solid">
					<a-radio-button value="local"> 本地文件 </a-radio-button>
					<a-radio-button value="folder"> 文件夹文件随机 </a-radio-button>
					<a-radio-button value="network"> 网络文件URL </a-radio-button>
				</a-radio-group>
				<a-dropdown :trigger="['click']" overlayClassName="selectVariableDropDown">
					<div class="wrap">
						<a-row :gutter="10">
							<a-col :span="20" @click.stop="e => e.preventDefault()">
								<a-input
									v-if="cur_config.type === 'local'"
									v-model="cur_config.url"
									placeholder="请填写本地文件路径，例如C:\Users\username\Desktop\test.txt" />
								<a-input
									v-if="cur_config.type === 'folder'"
									v-model="cur_config.url"
									placeholder="请填写本地文件夹路径，例如C:\Users\username\Desktop" />
								<a-input
									v-if="cur_config.type === 'network'"
									v-model="cur_config.url"
									placeholder="请填写网络文件URL路径，例如https://www.domain.com/test.txt" />
							</a-col>
							<a-col :span="4">
								<span style="color: #3860f4; cursor: pointer">使用变量</span>
							</a-col>
						</a-row>
					</div>
					<a-menu slot="overlay">
						<a-menu-item
							v-for="(item, index) in env_variables.concat(allVariables)"
							:key="index"
							@click="cur_config.url += '${' + item.value + '}'"
							style="display: flex; align-items: center; justify-content: space-between">
							<b>{{ item.value }}</b>
							<span>{{ item.label }}</span>
						</a-menu-item>
					</a-menu>
				</a-dropdown>
			</a-form-model-item>
			<a-form-model-item label="JavaScript" prop="content" v-if="isVisible(['javaScript'])">
				<div class="bg" style="background-color: #f4f6fe; border-radius: 6px; line-height: 22px" v-if="cur_config.params">
					<span
						>(function(<span style="color: #4c84ffed">{{ cur_config.params.join() }}</span
						>){</span
					>
					<a-textarea v-model="cur_config.content" :rows="6" placeholder="请输入JavaScript代码" />
					<span>})({{ cur_config.params.join() }});</span>
				</div>
			</a-form-model-item>
			<a-form-model-item label="注入变量" v-if="isVisible(['javaScript'])">
				<a-select v-model="cur_config.params" mode="multiple" style="width: 100%" placeholder="请选择变量" option-label-prop="value">
					<a-select-option v-for="(item, index) in env_variables.concat(allVariables)" :key="index" :value="item.value" :label="item.label">
						<div style="display: flex; align-items: center; justify-content: space-between">
							<b>{{ item.value }}</b>
							<span>{{ item.label }}</span>
						</div>
					</a-select-option>
				</a-select>
			</a-form-model-item>
			<a-form-model-item label="返回值保存至" v-if="isVisible(['javaScript'])">
				<a-input v-model="cur_config.variable" />
			</a-form-model-item>
			<a-form-model-item label="是否可见" v-if="isVisible(['waitForSelector'])">
				<a-switch default-checked @change="switchChange" />
			</a-form-model-item>
			<a-form-model-item label="坐标(X轴,Y轴)" prop="x" v-if="isVisible(['mouseClick', 'mouseMove'])">
				<a-row :gutter="10">
					<a-col :span="12">
						<a-input-number
							v-model="cur_config.x"
							:min="0"
							:max="100000"
							:step="1"
							:parser="value => value.replaceAll('.', '')"
							placeholder="X轴坐标"
							style="width: 100%" />
					</a-col>
					<a-col :span="12">
						<a-input-number
							v-model="cur_config.y"
							:min="0"
							:max="100000"
							:step="1"
							:parser="value => value"
							placeholder="Y轴坐标"
							style="width: 100%" />
					</a-col>
				</a-row>
			</a-form-model-item>
			<a-form-model-item label="过渡效果" v-if="isVisible(['scrollPage', 'mouseMove'])">
				<a-row :gutter="10">
					<a-col :span="12">
						<a-select v-model="cur_config.type">
							<a-select-option value="smooth"> 平滑 </a-select-option>
							<a-select-option value="moment"> 瞬间 </a-select-option>
						</a-select>
					</a-col>
				</a-row>
			</a-form-model-item>
			<a-form-model-item label="键盘按键" v-if="isVisible(['keyboard'])">
				<a-row :gutter="10">
					<a-col :span="12">
						<a-select v-model="cur_config.type">
							<a-select-option v-for="(item, index) in keyboard_list" :key="index" :value="item.value">
								{{ item.label }}
							</a-select-option>
						</a-select>
					</a-col>
				</a-row>
			</a-form-model-item>
			<a-form-model-item label="组合键" prop="command" v-if="isVisible(['keyCombination'])">
				<a-row :gutter="10">
					<a-col :span="12">
						<a-select v-model="cur_config.command">
							<a-select-option v-for="(item, index) in command_list" :key="index" :value="item.value">
								{{ item.label }}
							</a-select-option>
						</a-select>
					</a-col>
				</a-row>
			</a-form-model-item>
			<a-form-model-item label="超时等待" prop="timeoutMin" v-if="isVisible(['waitTime'])">
				<a-row :gutter="10">
					<a-col :span="8">
						<a-select v-model="cur_config.timeoutType">
							<a-select-option value="fixedValue"> 固定值 </a-select-option>
							<a-select-option value="randomInterval"> 区间随机 </a-select-option>
						</a-select>
					</a-col>
					<a-col :span="16">
						<a-input-number
							v-model="cur_config.timeout"
							:min="1"
							:max="3600000"
							:step="1"
							:parser="value => value.replaceAll('.', '')"
							v-if="cur_config.timeoutType === 'fixedValue'" />
						<div class="range" v-else style="display: inline-block">
							<a-input-number v-model="cur_config.timeoutMin" :min="0" :max="3600000" :step="1" :parser="value => value.replaceAll('.', '')" />
							<span> - </span>
							<a-input-number v-model="cur_config.timeoutMax" :min="0" :max="3600000" :step="1" :parser="value => value.replaceAll('.', '')" />
						</div>
						<span>&nbsp;&nbsp;&nbsp;毫秒</span>
					</a-col>
				</a-row>
			</a-form-model-item>
			<a-form-model-item label="请求URL" prop="url" v-if="isVisible(['waitForResponse', 'getRequest', 'getResponse'])">
				<a-input v-model="cur_config.url" placeholder="请输入请求URL包含的内容" />
			</a-form-model-item>
			<a-form-model-item label="提取类型" prop="key" v-if="isVisible(['getUrl'])">
				<a-row :gutter="10">
					<a-col :span="12">
						<a-select v-model="cur_config.type">
							<a-select-option v-for="(item, index) in getUrl_options" :key="index" :value="item.value">
								{{ item.label }}
							</a-select-option>
						</a-select>
					</a-col>
					<a-col :span="12">
						<a-input v-if="cur_config.type === 'search'" v-model="cur_config.key" placeholder="请输入要提取的参数名" />
					</a-col>
				</a-row>
			</a-form-model-item>
			<a-form-model-item label="提取类型" prop="key" v-if="isVisible(['getElement', 'forElements'])">
				<a-row :gutter="10">
					<a-col :span="12">
						<a-select v-model="cur_config.type">
							<a-select-option v-for="(item, index) in extraction_type_list" :key="index" :value="item.value">
								{{ item.label }}
							</a-select-option>
						</a-select>
					</a-col>
					<a-col :span="12">
						<a-input v-if="cur_config.type === 'attribute'" v-model="cur_config.key" placeholder="请输入要提取的属性名" />
					</a-col>
				</a-row>
			</a-form-model-item>
			<a-form-model-item label="选择Excel" prop="path" v-if="isVisible(['useExcel'])">
				<a-input v-model="cur_config.path" placeholder="请输入文件路径，例如C:\Users\username\Desktop\test.xlsx" />
			</a-form-model-item>
			<a-form-model-item label="选择文件" prop="path" v-if="isVisible(['importText'])">
				<a-input v-model="cur_config.path" placeholder="请输入文件路径，例如C:\Users\username\Desktop\test.txt" />
			</a-form-model-item>
			<a-form-model-item label="提取类型" prop="key" v-if="isVisible(['getRequest'])">
				<a-row :gutter="10">
					<a-col :span="12">
						<a-select v-model="cur_config.type">
							<a-select-option v-for="(item, index) in request_extraction_type_list" :key="index" :value="item.value">{{
								item.label
							}}</a-select-option>
						</a-select>
					</a-col>
					<a-col :span="12">
						<a-input
							v-if="cur_config.type === 'headers' || cur_config.type === 'getParams'"
							placeholder="请输入要提取参数的字段名"
							v-model="cur_config.key" />
					</a-col>
				</a-row>
			</a-form-model-item>
			<!-- 文本中提取 -->
			<a-form-model-item label="数据" prop="content" v-if="isVisible(['extractData'])">
				<a-select v-model="cur_config.content" option-label-prop="value">
					<a-select-option v-for="(item, index) in env_variables.concat(allVariables)" :key="index" :value="item.value">
						<div style="display: flex; justify-content: space-between">
							<b>{{ item.value }}</b>
							<span style="font-weight: normal">{{ item.label }}</span>
						</div>
					</a-select-option>
				</a-select>
			</a-form-model-item>
			<a-form-model-item label="数据" prop="content" v-if="isVisible(['forLists'])">
				<a-select v-model="cur_config.content" option-label-prop="value">
					<a-select-option v-for="(item, index) in objectVariables" :key="index" :value="item.value">
						<div style="display: flex; justify-content: space-between">
							<b>{{ item.value }}</b>
							<span style="font-weight: normal">{{ item.label }}</span>
						</div>
					</a-select-option>
				</a-select>
			</a-form-model-item>
			<a-form-model-item label="提取规则" prop="reg" v-if="isVisible(['extractData'])">
				<a-input v-model="cur_config.reg" placeholder="请输入提取规则" style="display: block" />
				<a-button style="margin-right: 10px; font-size: 12px" size="small" type="dashed" @click="fillReg('number')">6位数字</a-button>
				<a-button style="margin-right: 10px; font-size: 12px" size="small" type="dashed" @click="fillReg('url')">链接URL</a-button>
				<a-button style="margin-right: 10px; font-size: 12px" size="small" type="dashed" @click="fillReg('email')">邮箱</a-button>
				<a-row :gutter="10">
					<a-col :span="6">
						<a-checkbox @change="switchChange"> 忽略大小写 </a-checkbox>
					</a-col>
					<a-col :span="8">
						<a-checkbox defaultChecked disabled> 只提取第一个匹配项 </a-checkbox>
					</a-col>
				</a-row>
			</a-form-model-item>
			<a-form-model-item label="转换变量" prop="content" v-if="isVisible(['toJson'])">
				<a-select v-model="cur_config.content" option-label-prop="value">
					<a-select-option v-for="(item, index) in env_variables.concat(allVariables)" :key="index" :value="item.value">
						<div style="display: flex; justify-content: space-between">
							<b>{{ item.value }}</b>
							<span style="font-weight: normal">{{ item.label }}</span>
						</div>
					</a-select-option>
				</a-select>
			</a-form-model-item>
			<!-- 使用 <转换JSON对象> 中创建的变量作为数据 -->
			<a-form-model-item label="数据" prop="content" v-if="isVisible(['extractKey', 'randomGet'])">
				<a-select v-model="cur_config.content" option-label-prop="value">
					<a-select-option v-for="(item, index) in objectVariables" :key="index" :value="item.value">
						<div style="display: flex; justify-content: space-between">
							<b>{{ item.value }}</b>
							<span style="font-weight: normal">{{ item.label }}</span>
						</div>
					</a-select-option>
				</a-select>
			</a-form-model-item>
			<a-form-model-item label="提取Key" prop="key" v-if="isVisible(['extractKey'])">
				<a-input v-model="cur_config.key" placeholder="请输入提取的key" />
			</a-form-model-item>
			<a-form-model-item
				label="保存至"
				prop="variable"
				v-if="
					isVisible([
						'getUrl',
						'getElement',
						'getActiveElement',
						'useExcel',
						'importText',
						'getRequest',
						'getResponse',
						'extractData',
						'toJson',
						'extractKey',
						'randomGet',
					])
				">
				<a-input v-model="cur_config.variable" placeholder="保存的变量名" />
			</a-form-model-item>
			<a-form-model-item label="文件名" prop="name" v-if="isVisible(['saveData'])">
				<a-dropdown :trigger="['click']" overlayClassName="selectVariableDropDown">
					<div class="wrap">
						<a-row :gutter="10">
							<a-col :span="20" @click.stop="e => e.preventDefault()">
								<a-input-search v-model="cur_config.name" placeholder="请输入文件名">
									<a-button slot="enterButton"> -${任务ID}.txt </a-button>
								</a-input-search>
							</a-col>
							<a-col :span="4">
								<span style="color: #3860f4; cursor: pointer">使用变量</span>
							</a-col>
						</a-row>
					</div>
					<a-menu slot="overlay">
						<a-menu-item
							v-for="(item, index) in env_variables.concat(allVariables)"
							:key="index"
							@click="cur_config.name += '${' + item.value + '}'"
							style="display: flex; align-items: center; justify-content: space-between">
							<b>{{ item.value }}</b>
							<span>{{ item.label }}</span>
						</a-menu-item>
					</a-menu>
				</a-dropdown>
			</a-form-model-item>
			<a-form-model-item label="文件名" prop="name" v-if="isVisible(['exportExcel'])">
				<a-dropdown :trigger="['click']" overlayClassName="selectVariableDropDown">
					<div class="wrap">
						<a-row :gutter="10">
							<a-col :span="20" @click.stop="e => e.preventDefault()">
								<a-input-search v-model="cur_config.name" placeholder="请输入文件名">
									<a-button slot="enterButton"> -${任务ID}.xlsx </a-button>
								</a-input-search>
							</a-col>
							<a-col :span="4">
								<span style="color: #3860f4; cursor: pointer">使用变量</span>
							</a-col>
						</a-row>
					</div>
					<a-menu slot="overlay">
						<a-menu-item
							v-for="(item, index) in env_variables.concat(allVariables)"
							:key="index"
							@click="cur_config.name += '${' + item.value + '}'"
							style="display: flex; align-items: center; justify-content: space-between">
							<b>{{ item.value }}</b>
							<span>{{ item.label }}</span>
						</a-menu-item>
					</a-menu>
				</a-dropdown>
			</a-form-model-item>
			<a-form-model-item label="保存模板" prop="template" v-if="isVisible(['saveData'])">
				<a-dropdown :trigger="['click']" overlayClassName="selectVariableDropDown" :key="123">
					<div class="wrap">
						<a-row :gutter="10">
							<a-col :span="20" @click.stop="e => e.preventDefault()">
								<a-textarea
									v-model="cur_config.template"
									:auto-size="{ minRows: 5, maxRows: 5 }"
									placeholder="请输入保存的信息和获取数据的变量">
								</a-textarea>
							</a-col>
							<a-col :span="4">
								<span style="color: #3860f4; cursor: pointer">使用变量</span>
							</a-col>
						</a-row>
					</div>
					<a-menu slot="overlay">
						<a-menu-item
							v-for="(item, index) in env_variables.concat(allVariables)"
							:key="index"
							@click="cur_config.template += '${' + item.value + '}'"
							style="display: flex; align-items: center; justify-content: space-between">
							<b>{{ item.value }}</b>
							<span>{{ item.label }}</span>
						</a-menu-item>
					</a-menu>
				</a-dropdown>
			</a-form-model-item>
			<a-form-model-item label="选择保存列" prop="fields" v-if="isVisible(['exportExcel'])">
				<a-select v-model="cur_config.fields" mode="multiple" style="width: 100%" placeholder="请选择变量" option-label-prop="value">
					<a-select-option v-for="(item, index) in env_variables.concat(allVariables)" :key="index" :value="item.value" :label="item.label">
						<div style="display: flex; align-items: center; justify-content: space-between">
							<b>{{ item.value }}</b>
							<span>{{ item.label }}</span>
						</div>
					</a-select-option>
				</a-select>
			</a-form-model-item>
			<a-form-model-item label="下载地址" prop="url" v-if="isVisible(['downloadFile'])">
				<a-dropdown :trigger="['click']" overlayClassName="selectVariableDropDown">
					<div class="wrap">
						<a-row :gutter="10">
							<a-col :span="20" @click.stop="e => e.preventDefault()">
								<a-input v-model="cur_config.url" placeholder="请输入下载地址" />
							</a-col>
							<a-col :span="4">
								<span style="color: #3860f4; cursor: pointer">使用变量</span>
							</a-col>
						</a-row>
					</div>
					<a-menu slot="overlay">
						<a-menu-item
							v-for="(item, index) in env_variables.concat(allVariables)"
							:key="index"
							@click="cur_config.url += '${' + item.value + '}'"
							style="display: flex; align-items: center; justify-content: space-between">
							<b>{{ item.value }}</b>
							<span>{{ item.label }}</span>
						</a-menu-item>
					</a-menu>
				</a-dropdown>
			</a-form-model-item>
			<a-form-model-item label="保存路径" prop="path" v-if="isVisible(['downloadFile'])">
				<a-input v-model="cur_config.path" placeholder="请输入文件夹路径" />
			</a-form-model-item>
			<a-form-model-item label="变量" prop="condition" v-if="isVisible(['ifElse'])">
				<a-select v-model="cur_config.condition" option-label-prop="value" placeholder="请选择变量">
					<a-select-option v-for="(item, index) in env_variables.concat(allVariables)" :key="index" :value="'${' + item.value + '}'">
						<div style="display: flex; justify-content: space-between">
							<b>{{ item.value }}</b>
							<span style="font-weight: normal">{{ item.label }}</span>
						</div>
					</a-select-option>
				</a-select>
			</a-form-model-item>
			<a-form-model-item label="条件" prop="relation" v-if="isVisible(['ifElse'])">
				<a-row :gutter="10">
					<a-col :span="12">
						<a-select v-model="cur_config.relation">
							<a-select-option v-for="(item, index) in if_relation" :key="index" :value="item.value">
								{{ item.label }}
							</a-select-option>
						</a-select>
					</a-col>
				</a-row>
			</a-form-model-item>
			<a-form-model-item label="结果" prop="result" v-if="isVisible(['ifElse']) && cur_config.relation !== 'exist' && cur_config.relation !== 'notExist'">
				<a-dropdown :trigger="['click']" overlayClassName="selectVariableDropDown">
					<div class="wrap">
						<a-row :gutter="10">
							<a-col :span="20" @click.stop="e => e.preventDefault()">
								<a-input v-model="cur_config.result" />
							</a-col>
							<a-col :span="4">
								<span style="color: #3860f4; cursor: pointer">使用变量</span>
							</a-col>
						</a-row>
					</div>
					<a-menu slot="overlay">
						<a-menu-item
							v-for="(item, index) in env_variables.concat(allVariables)"
							:key="index"
							@click="cur_config.result += '${' + item.value + '}'"
							style="display: flex; align-items: center; justify-content: space-between">
							<b>{{ item.value }}</b>
							<span>{{ item.label }}</span>
						</a-menu-item>
					</a-menu>
				</a-dropdown>
			</a-form-model-item>
			<a-form-model-item label="循环对象保存至" v-if="isVisible(['forElements', 'forLists'])">
				<a-input v-model="cur_config.variable" />
			</a-form-model-item>
			<a-form-model-item label="循环次数" v-if="isVisible(['forTimes'])">
				<a-input-number v-model="cur_config.times" :min="1" :max="1000" :step="1" style="width: 130px" :parser="value => value.replaceAll('.', '')" />
			</a-form-model-item>
			<a-form-model-item label="循环位置保存至" v-if="isVisible(['forElements', 'forTimes', 'forLists'])">
				<a-input v-model="cur_config.variableIndex" />
			</a-form-model-item>
			<a-form-model-item label="超时等待" prop="timeout" v-if="cur_config.hasOwnProperty('timeout') && target.type !== 'waitTime'">
				<a-input-number
					v-model="cur_config.timeout"
					:min="1"
					:max="3600000"
					:step="100"
					style="width: 130px"
					:parser="value => value.replaceAll('.', '')" />
				<span>&nbsp;&nbsp;&nbsp;毫秒</span>
			</a-form-model-item>
			<a-form-model-item label="说明">
				<a-input placeholder="选填" v-model="cur_config.remark" :maxLength="30">
					<span slot="suffix">{{ cur_config.remark ? cur_config.remark.length : 0 }}/30</span>
				</a-input>
			</a-form-model-item>
		</a-form-model>
	</a-modal>
</template>

<script>
import {
	env_variables,
	form_rules,
	keyboard_list,
	command_list,
	extraction_type_list,
	request_extraction_type_list,
	if_relation,
	operate_name,
	relation_options,
	tab_type_options,
	click_button,
	click_type,
	scroll_position,
	getUrl_options,
} from "@/utils/rpa.js";
export default {
	components: {},
	name: "ConfigDialog",
	props: {
		visible: {
			type: Boolean,
			default: false,
		},
		config: {
			type: Object,
			default: () => ({}),
		},
		target: {
			type: Object,
			default: () => ({}),
		},
		allVariables: {
			type: Array,
			default: () => [],
		},
		elementObject: {
			type: Array,
			default: () => [],
		},
		objectVariables: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			env_variables,
			keyboard_list,
			command_list,
			extraction_type_list,
			request_extraction_type_list,
			if_relation,
			operate_name,
			relation_options,
			tab_type_options,
			click_button,
			click_type,
			scroll_position,
			getUrl_options,
			cur_config: {},
			marks: {
				10: "低",
				50: "中",
				75: "高",
				90: "最佳",
			},
			rules: {
				...form_rules,
				content: [
					{
						validator: this.contentValidator,
						trigger: "blur",
					},
				],
				serial: [
					{
						validator: this.serialValidator,
						trigger: "blur",
					},
				],
				distance: [
					{
						validator: this.distanceValidator,
						trigger: "blur",
					},
				],
				x: [
					{
						validator: this.positionValidator,
						trigger: "blur",
					},
				],
				timeoutMin: [
					{
						validator: this.timeoutMinValidator,
						trigger: "blur",
					},
				],
				key: [
					{
						validator: this.keyValidator,
						trigger: "blur",
					},
				],
				variable: [
					{
						validator: this.variableValidator,
						trigger: "blur",
					},
				],
				fields: [
					{
						validator: this.fieldsValidator,
						trigger: "blur",
					},
				],
				result: [
					{
						validator: this.resultValidator,
						trigger: "blur",
					},
				],
			},
		};
	},
	watch: {
		visible(newVal) {
			if (newVal) {
				this.cur_config = JSON.parse(JSON.stringify(this.config));
			}
		},
	},
	methods: {
		cancel() {
			this.$emit("update:visible", false);
			this.$emit("cancel");
		},
		ok() {
			this.$refs.configForm.validate(result => {
				if (result) {
					this.$emit("confirm", {
						...this.target,
						config: this.cur_config,
					});
				}
			});
		},
		// 控制表单元素是否显示
		isVisible(arr) {
			return arr.includes(this.target.type);
		},
		switchChange(e) {
			switch (this.target.type) {
				case "screenshotPage":
					this.cur_config.fullPage = `${Number(e)}`;
					break;
				case "waitForSelector":
					this.cur_config.isShow = `${Number(e)}`;
					break;
				case "extractData":
					let { checked } = e.target;
					this.cur_config.notUpper = Number(checked);
					break;
				default:
					break;
			}
		},
		fillReg(type) {
			switch (type) {
				case "number":
					this.cur_config.reg = "\d{6,6}";
					break;
				case "url":
					this.cur_config.reg = '((https|http|ftp|rtsp|mms)?:\/\/)+[A-Za-z0-9]+\.[A-Za-z0-9]+[\/=\?%\-&_~\`@[\]\':+!]*([^<>""])*';
					break;
				case "email":
					this.cur_config.reg = "[A-Za-z0-9\\u4e00-\\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+";
					break;
				default:
					break;
			}
		},
		// 验证
		serialValidator(rule, value, callback) {
			if (this.cur_config.serialType === "randomInterval") {
				if (!this.cur_config.serialMin || !this.cur_config.serialMax) {
					callback("请输入区间随机的最小值和最大值");
				} else if (this.cur_config.serialMin > this.cur_config.serialMax) {
					callback("区间无效");
				} else {
					callback();
				}
			} else {
				if (!this.cur_config.serial) {
					callback("请输入元素顺序");
				} else {
					callback();
				}
			}
		},
		distanceValidator(rule, value, callback) {
			if (this.cur_config.scrollType === "pixel" && !value) {
				callback("请输入滚动位置");
			} else {
				callback();
			}
		},
		contentValidator(rules, value, callback) {
			if (value) {
				return callback();
			} else {
				switch (this.target.type) {
					case "switchPage":
						callback("请输入标签信息");
						break;
					case "javaScript":
						callback("请输入JS脚本");
						break;
					case "extractData":
						callback("请选择数据");
						break;
					case "toJson":
						callback("请选择转换变量");
						break;
					case "extractKey":
						callback("请选择数据");
						break;
					default:
						break;
				}
			}
		},
		positionValidator(rules, value, callback) {
			let { x, y } = this.cur_config;
			if (x === null || y === null) {
				callback("请输入坐标");
			} else {
				callback();
			}
		},
		timeoutMinValidator(rules, value, callback) {
			if (this.cur_config.timeoutType === "fixedValue") {
				if (this.cur_config.timeout) {
					callback();
				} else {
					callback("请输入超时时间");
				}
			} else {
				if (!this.cur_config.timeoutMin || !this.cur_config.timeoutMax) {
					callback("请输入超时时间");
				} else if (this.cur_config.timeoutMin > this.cur_config.timeoutMax) {
					callback("区间无效");
				} else {
					callback();
				}
			}
		},
		keyValidator(rules, value, callback) {
			switch (this.target.type) {
				case "getUrl":
					if (this.cur_config.type === "search" && !value) {
						callback("请输入要提取参数的字段名");
					} else {
						callback();
					}
					break;
				case "getElement":
				case "forElements":
					if (this.cur_config.type === "attribute" && !value) {
						callback("请输入要提取的属性名");
					} else {
						callback();
					}
				case "getRequest":
					if ((this.cur_config.type === "headers" || this.cur_config.type === "getParams") && !value) {
						callback("请输入要提取参数的字段名");
					} else {
						callback();
					}
					break;
				case "extractKey":
					if (value) {
						callback();
					} else {
						callback("请输入提取的key");
					}
					break;
				default:
					callback();
					break;
			}
		},
		variableValidator(rules, value, callback) {
			switch (this.target.type) {
				case "getActiveElement":
				case "useExcel":
				case "importText":
					if (value) {
						if (/^[^0-9\s][^\s]*$/.test(value)) {
							callback();
						} else {
							callback("格式错误，变量不能以数字开头且不能包含空格！");
						}
					} else {
						callback("请输入变量名");
					}
					break;
				default:
					if (value) {
						if (/^[^0-9\s][^\s]*$/.test(value)) {
							callback();
						} else {
							callback("格式错误，变量不能以数字开头且不能包含空格！");
						}
					} else {
						callback();
					}
					break;
			}
		},
		fieldsValidator(rules, value, callback) {
			if (rules.length === 0) callback("请选择变量作为表格的列名称");
			else callback();
		},
		resultValidator(rules, value, callback) {
			if (this.cur_config.relation !== "exist" || this.cur_config.relation !== "notExist") {
				if (!this.cur_config.result) {
					callback("请输入结果");
				} else {
					callback();
				}
			} else {
				callback();
			}
		},
	},
};
</script>
<style lang="less" scoped>
::v-deep .ant-modal {
	.ant-modal-content {
		.ant-modal-title {
			font-weight: bold;
			font-size: 18px;
		}
	}
}
</style>
<style>
.selectVariableDropDown {
	background-color: #fff;
	border-radius: 4px;
}
</style>
