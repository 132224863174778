<template>
	<Draggable class="dragArea" :list="tasks" group="people" v-bind="dragOptions" :forceFallback="true">
		<div class="task" v-for="(el, index) in tasks" @dblclick.stop="edit(index)" :key="Math.random()">
			<div class="header">
				<div class="left">
					<div class="sort">
						<img src="@/assets/img/rpa/create/icon_sort.svg" title="移动" alt="" />
					</div>
					<p class="task-name">{{ operate_name[el.type] }}</p>
				</div>
				<div class="operate">
					<img src="@/assets/img/rpa/create/icon_edit.svg" v-if="el.config.hasOwnProperty('remark')" @click.stop="edit(index)" title="编辑" alt="" />
					<img src="@/assets/img/rpa/create/icon_copy.svg" @click="copy(index)" title="复制" alt="" />
					<img src="@/assets/img/rpa/create/icon_delete.svg" @click="del(index)" title="删除" alt="" />
				</div>
			</div>
			<div class="info">
				<p v-html="getTaskTip(el)"></p>
			</div>
			<div class="remark" v-if="el.config.remark">
				<img src="@/assets/img/rpa/create/icon_remark.svg" title="备注" alt="" />
				<p>{{ el.config.remark }}</p>
			</div>
			<div class="subTask">
				<DraggableGroup v-if="el.config.children" :tasks="el.config.children" />
				<div class="else" v-if="el.type === 'ifElse'">
					<div class="header">
						<p class="task-name" v-if="showElse">Else</p>
						<span class="operate" v-if="!showElse" @click="showElse = true">添加Else</span>
						<span class="operate" v-else @click="removeElse(index)">移除Else</span>
					</div>
					<DraggableGroup v-if="showElse" :tasks="el.config.other" />
				</div>
			</div>
		</div>
	</Draggable>
</template>
<script>
import Draggable from "vuedraggable";
import {
	operate_name,
	taskDescription,
	tab_type_options,
	relation_options,
	click_button,
	click_type,
	scroll_position,
	keyboard_list,
	command_list,
	getUrl_options,
	extraction_type_list,
	request_extraction_type_list,
	if_relation,
} from "@/utils/rpa.js";
export default {
	name: "DraggableGroup",
	components: {
		Draggable,
	},
	props: {
		tasks: {
			required: true,
			type: Array,
		},
	},
	data() {
		return {
			operate_name,
			showElse: false,
		};
	},
	mounted() {},
	methods: {
		copy(index) {
			this.$store.commit("updateIsCopy", true);
			this.tasks.splice(index + 1, 0, JSON.parse(JSON.stringify(this.tasks[index])));
		},
		del(index) {
			this.tasks.splice(index, 1);
		},
		edit(index) {
			this.$store.commit("updateEditTask", { index, tasks: this.tasks });
		},
		removeElse(index) {
			this.showElse = false;
			this.tasks[index].config.other = [];
		},
		getTaskTip({ type: task_type, config }) {
			let str = taskDescription[task_type];
			switch (task_type) {
				case "switchPage":
					var { type, relation, content } = config;
					return this.generateTip(
						str,
						tab_type_options.concat(relation_options),
						{ value: type, replace: true },
						{ value: relation, replace: true },
						{ value: content, replace: false }
					);
				case "gotoUrl":
					var { url, timeout } = config;
					return this.generateTip(str, [], { value: url, replace: false }, { value: timeout, replace: false });
				case "refreshPage":
					var { timeout } = config;
					return this.generateTip(str, [], {
						value: timeout,
						replace: false,
					});
				case "goBack":
					var { timeout } = config;
					return this.generateTip(str, [], {
						value: timeout,
						replace: false,
					});
				case "screenshotPage":
					var { name, fullPage, format } = config;
					return this.generateTip(
						str,
						[],
						{
							value: name || "任务ID+用户ID+时间戳",
							replace: false,
						},
						{ value: +fullPage ? "否" : "是", replace: false },
						{ value: format, replace: false }
					);
				case "passingElement":
				case "focusElement":
					var { selector, serial, serialMin, serialMax, selectorType, element, serialType } = config;
					return this.generateTip(
						str,
						[],
						{
							value: selectorType === "selector" ? "元素选择器" : "储存的元素对象",
							replace: false,
						},
						{
							value: selectorType === "selector" ? selector : element,
							replace: false,
						},
						{
							value: selectorType === "selector" ? "元素顺序" : "",
							replace: false,
						},
						{
							value: selectorType === "selector" ? (serialType === "fixedValue" ? serial : `${serialMin} - ${serialMax}`) : "",
						}
					);
				case "selectElement":
					var { selector, serial, serialMin, serialMax, selectorType, element, serialType, value } = config;
					return this.generateTip(
						str,
						[],
						{
							value: selectorType === "selector" ? "元素选择器" : "储存的元素对象",
							replace: false,
						},
						{
							value: selectorType === "selector" ? selector : element,
							replace: false,
						},
						{
							value: selectorType === "selector" ? "元素顺序" : "",
							replace: false,
						},
						{
							value: selectorType === "selector" ? (serialType === "fixedValue" ? serial : `${serialMin} - ${serialMax}`) : "",
						},
						{ value: value, replace: false }
					);
				case "click":
					var { selector, serial, serialMin, serialMax, selectorType, element, serialType, type, button } = config;
					return this.generateTip(
						str,
						click_button.concat(click_type),
						{
							value: selectorType === "selector" ? "元素选择器" : "储存的元素对象",
							replace: false,
						},
						{
							value: selectorType === "selector" ? selector : element,
							replace: false,
						},
						{
							value: selectorType === "selector" ? "元素顺序" : "",
							replace: false,
						},
						{
							value: selectorType === "selector" ? (serialType === "fixedValue" ? serial : `${serialMin} - ${serialMax}`) : "",
						},
						{ value: type, replace: true },
						{ value: button, replace: true }
					);
				case "inputContent":
					var { selector, serial, serialMin, serialMax, selectorType, element, serialType, intervals, randomContent } = config;
					return this.generateTip(
						str,
						[],
						{
							value: selectorType === "selector" ? "元素选择器" : "储存的元素对象",
							replace: false,
						},
						{
							value: selectorType === "selector" ? selector : element,
							replace: false,
						},
						{
							value: selectorType === "selector" ? "元素顺序" : "",
							replace: false,
						},
						{
							value: selectorType === "selector" ? (serialType === "fixedValue" ? serial : `${serialMin} - ${serialMax}`) : "",
						},
						{ value: intervals, replace: false },
						{ value: randomContent, replace: false }
					);
				case "scrollPage":
					var { scrollType, position, distance, type } = config;
					return this.generateTip(
						str,
						scroll_position,
						{
							value: scrollType === "position" ? "位置" : "滚动距离",
						},
						{
							value: scrollType === "position" ? position : distance + "像素",
							replace: scrollType === "position" ? true : false,
						},
						{
							value: type === "smooth" ? "平滑" : "瞬间",
							replace: false,
						}
					);
				case "uploadAttachment":
					var { selector, serial, serialMin, serialMax, selectorType, element, serialType, url, timeout } = config;
					return this.generateTip(
						str,
						[],
						{
							value: selectorType === "selector" ? "元素选择器" : "储存的元素对象",
							replace: false,
						},
						{
							value: selectorType === "selector" ? selector : element,
							replace: false,
						},
						{
							value: selectorType === "selector" ? "元素顺序" : "",
							replace: false,
						},
						{
							value: selectorType === "selector" ? (serialType === "fixedValue" ? serial : `${serialMin} - ${serialMax}`) : "",
						},
						{ value: url, replace: false },
						{ value: timeout, replace: false }
					);
				case "javaScript":
					var { variable, params } = config;
					return this.generateTip(
						str,
						[],
						{
							value: params.length ? `注入变量<span>${params.join(" , ")}</span>` : "",
						},
						{
							value: variable ? `将返回结果保存至:<span>${variable}</span>` : "",
						}
					);
				case "mouseClick":
					var { type, button, x, y } = config;
					return this.generateTip(
						str,
						click_button.concat(click_type),
						{ value: type, replace: true },
						{ value: button, replace: true },
						{ value: `(${x},${y})` }
					);
				case "mouseMove":
					var { type, x, y } = config;
					return this.generateTip(str, [], { value: type === "smooth" ? "平滑" : "瞬间" }, { value: `(${x},${y})` });
				case "keyboard":
					var { type } = config;
					return this.generateTip(str, keyboard_list, {
						value: type,
						replace: true,
					});
				case "keyCombination":
					var { command } = config;
					return this.generateTip(str, command_list, {
						value: command,
						replace: true,
					});
				case "waitTime":
					var { timeout, timeoutType, timeoutMax, timeoutMin } = config;
					return this.generateTip(str, [], {
						value: timeoutType === "fixedValue" ? timeout : `${timeoutMin} - ${timeoutMax}`,
					});
				case "waitForSelector":
					var { selector, timeout, serial, isShow } = config;
					return this.generateTip(str, [], { value: selector }, { value: serial }, { value: timeout }, { value: +isShow ? "是" : "否" });
				case "waitForResponse":
					var { url, timeout } = config;
					return this.generateTip(str, [], { value: url }, { value: timeout });
				case "getUrl":
					var { type, key, variable } = config;
					return this.generateTip(
						str,
						getUrl_options,
						{ value: type, replace: true },
						{
							value: type === "search" ? `提取参数 <span>${key}</span>` : "",
						},
						{
							value: variable ? `保存至 <span>${variable}</span>` : "",
						}
					);
				case "getElement":
					var { selector, selectorType, element, serialType, serial, serialMin, serialMax, type, key, variable } = config;
					return this.generateTip(
						str,
						extraction_type_list,
						{
							value: selectorType === "selector" ? "元素选择器" : "储存的元素对象",
						},
						{
							value: selectorType === "selector" ? selector : element,
						},
						{
							value: selectorType === "selector" ? "元素顺序" : "",
							replace: false,
						},
						{
							value: selectorType === "selector" ? (serialType === "fixedValue" ? serial : `${serialMin} - ${serialMax}`) : "",
						},
						{ value: type, replace: true },
						{ value: type === "attribute" ? "提取属性" : "" },
						{ value: type === "attribute" ? key : "" },
						{
							value: variable ? `保存至 <span>${variable}</span>` : "",
						}
					);
				case "getActiveElement":
					var { variable } = config;
					return this.generateTip(str, [], { value: variable });
				case "saveData":
					var { name } = config;
					return this.generateTip(str, [], { value: name });
				case "exportExcel":
					var { name, fields } = config;
					return this.generateTip(str, [], { value: name }, { value: fields.join(" , ") });
				case "downloadFile":
					var { url, path } = config;
					return this.generateTip(str, [], { value: url }, { value: path });
				case "useExcel":
					var { path, variable } = config;
					return this.generateTip(str, [], { value: path }, { value: variable });
				case "importText":
					var { path, variable } = config;
					return this.generateTip(str, [], { value: path }, { value: variable });
				case "getRequest":
					var { url, type, key, variable } = config;
					return this.generateTip(
						str,
						request_extraction_type_list,
						{ value: url },
						{ value: type, replace: true },
						{
							value: ["headers", "getParams"].includes(type) ? `中的 <span>${key}</span>` : "",
						},
						{ value: variable }
					);
				case "getResponse":
					var { url, variable } = config;
					return this.generateTip(str, [], { value: url }, { value: variable });

				case "extractData":
					var { content, reg, variable } = config;
					return this.generateTip(str, [], { value: reg }, { value: content }, { value: variable });
				case "toJson":
					var { content, variable } = config;
					return this.generateTip(str, [], { value: variable }, { value: content });
				case "extractKey":
					var { content, key, variable } = config;
					return this.generateTip(str, [], { value: content }, { value: key }, { value: variable });
				case "randomGet":
					var { content, variable } = config;
					return this.generateTip(str, [], { value: content }, { value: variable });
				case "ifElse":
					var { condition, relation, result } = config;
					return this.generateTip(
						str,
						if_relation,
						{ value: condition },
						{ value: relation, replace: true },
						{
							value: ["exist", "notExist"].includes(relation) ? "" : result,
						}
					);
				case "forElements":
					var { selector, type, key, variable, variableIndex } = config;
					return this.generateTip(
						str,
						extraction_type_list,
						{ value: selector },
						{ value: type, replace: true },
						{
							value: type === "attribute" ? `属性名 <span>${key}</span>` : "",
						},
						{ value: variable },
						{ value: variableIndex }
					);
				case "forTimes":
					var { times, variableIndex } = config;
					return this.generateTip(str, [], { value: times }, { value: variableIndex });
				case "forLists":
					var { content, variable, variableIndex } = config;
					return this.generateTip(str, [], { value: content }, { value: variable }, { value: variableIndex });
				default:
					return this.operate_name[task_type];
			}
		},
		// 循环替换 _PLACEHOLDER_
		generateTip(str, options, ...args) {
			let origin_str = str;
			for (let i = 0; i < args.length; i++) {
				let res = args[i].replace && args[i].value ? options.find(item => item.value === args[i].value).label : args[i].value;
				origin_str = origin_str.replace("_PLACEHOLDER_", `${res}`);
			}
			return origin_str;
		},
	},

	computed: {
		dragOptions() {
			return {
				animation: 200,
				group: "description",
				disabled: false,
				ghostClass: "ghost",
			};
		},
	},
};
</script>
<style lang="less" scoped>
.dragArea {
	padding: 16px;
	user-select: none;
	::v-deep .operate-draggableItem {
		height: 40px;
		line-height: 40px;
		border-radius: 6px;
		opacity: 0.6;
		color: #3860f4;
		background-color: #ecf2ff;
		padding-left: 16px;
		margin-bottom: 10px;
		svg {
			display: none;
		}
	}
	.task {
		background-color: #fff;
		padding: 6px 16px 10px;
		border-radius: 6px;
		box-shadow: 0 3px 5px rgba(0, 0, 0, 0.06);
		margin-bottom: 10px;
		cursor: grab;
		border: 1px solid transparent;

		.header {
			display: flex;
			align-items: center;
			justify-content: space-between;
			height: 30px;
			.left {
				display: flex;
				align-items: center;
				.sort {
					img {
						display: block;
					}
				}
				.task-name {
					font-size: 14px;
					font-weight: bold;
					color: #333;
					margin-left: 8px;
				}
			}
			.operate {
				display: flex;
				align-items: center;
				cursor: pointer;
				height: 36px;
				img {
					display: block;
					margin-left: 12px;
					transition: all 0.2s;
					opacity: 0.3;
					&:hover {
						transform: scale(1.2);
						opacity: 0.9;
					}
				}
			}
		}
		.info {
			padding-left: 28px;
			margin-top: 2px;
			color: #888;
			p {
				::v-deep span {
					color: #3860f4;
					text-decoration: underline;
				}
			}
		}
		.remark {
			padding-left: 28px;
			margin-top: 2px;
			color: #888;
			display: flex;
			align-items: center;
			svg {
				flex: 0 0 16px;
			}
			p {
				margin-left: 4px;
			}
		}
		.subTask {
			padding-left: 24px;
			.dragArea {
				border: 1px dashed #b3c2f8;
				border-radius: 6px;
				min-height: 60px;
				margin-top: 10px;
				.task {
					border: 1px solid rgba(0, 0, 0, 0.1);
				}
			}
			.else {
				.header {
					.task-name {
						font-size: 14px;
						font-weight: bold;
						color: #333;
						margin-left: 8px;
					}
					.operate {
						color: #3860f4;
						cursor: pointer;
					}
				}
			}
		}
	}
}
</style>
<style>
.task.sortable-chosen.ghost {
	opacity: 0.3;
	border: 1px dashed #3860f4 !important;
}
.task.sortable-chosen.sortable-fallback {
	opacity: 1 !important;
	border: 1px dashed #3860f4 !important;
}
</style>
