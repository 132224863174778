<template>
	<div class="SelectEnvironment">
		<a-modal :visible="visible" title="调试" :width="500" @cancel="cancel" @ok="ok" :destroyOnClose="true" :maskClosable="false" :confirm-loading="loading">
			<div class="content">
				<b>请选择环境：</b>
				<a-select v-model="selected_env" option-label-prop="label" placeholder="请选择环境">
					<a-select-option v-for="(item, index) in env_list" :key="index" :value="item.id" :label="item.env_name">
						{{ item.env_name }}&nbsp;&nbsp;&nbsp; (ID:{{ item.id }})
					</a-select-option>
				</a-select>
			</div>
		</a-modal>
	</div>
</template>

<script>
import { environment_index } from "@/api/home";
import { environment_get_config } from "@/api/environment.js";
import { languageList } from "@/utils/languageList";

export default {
	props: {
		visible: {
			default: false,
		},
	},
	components: {},
	name: "SelectEnvironment",
	data() {
		return {
			selected_env: undefined,
			env_list: [],
			loading: false,
		};
	},
	watch: {
		visible(newVal) {
			if (newVal) {
				this.getEnvList();
			}
		},
		"$store.state.rpa_message": {
			handler(newVal) {
				this.loading = false;
				if (newVal.error_msg) {
					this.$message.error(newVal.error_msg);
				} else {
					this.$emit("ok", newVal);
					this.loading = false;
					this.cancel();
				}
			},
			deep: true,
		},
	},
	methods: {
		cancel() {
			this.$emit("update:visible", false);
		},
		ok() {
			if (this.selected_env) {
				const target = this.env_list.find(item => item.id == this.selected_env);
				this.run(target);
			} else {
				this.$message.error("请选择要执行调试的环境");
			}
		},
		async run(env) {
			this.loading = true;
			// 这里直接复制的打开环境的前置操作逻辑
			let { data } = await environment_get_config({
				id: env.id,
				env_id: env.id,
			});
			let user = localStorage.user ? JSON.parse(localStorage.member || {}) : {};
			let req = {
				...env,
				...data.data,
				// transport_ip: "101.89.44.90:40000",
			};

			// 将指纹参数解构到最外层
			try {
				let { fingerprint, ...reset } = req;
				req = {
					...reset,
					...fingerprint,
					user,
				};
			} catch (error) {}

			if (req.location.is_baseon_location) {
				req.location.longitude = req.lngwgs;
				req.location.latitude = req.latwgs;
			}
			//从国家语言映射表中匹配ip地址对应的语言，匹配不到则默认为英语en
			const languageItem = languageList.find(i => i.country.includes(req.country));
			//判断是否根据ip生成时区。首先调用第三方库查找，找不到则在本地列表查找，再找不到则为""
			try {
				if (!req.is_custom_timezone) {
					req.timezone = find(req.latwgs, req.lngwgs)[0];
				}
			} catch (error) {
				const timezoneAlt = timezoneList[req.areacode];
				if (timezoneAlt) {
					req.timezone = timezoneAlt[0];
				} else {
					req.timezone = "";
				}
			}

			req.lang = languageItem ? languageItem.value : "en";
			req.device_ip = `${req.device_ip}`;
			// 是否成功从语言列表(languageList)中匹配到语言
			req.isIPLanguageOK = languageItem ? true : false;
			//插件对应的字段，req.extensions,[{id:,guid:,download_url:""}]
			if (data.code == 200) {
				const params = {
					message: "/connection/open_rpa_env",
					alldata: {
						...req,
						is_rpa_mode: true,
					},
					token: localStorage.token,
				};
				this.$store.state.websocket.send(this.$encrypt(JSON.stringify(params)));
			}
		},
		getEnvList() {
			environment_index({ pagesize: 999 })
				.then(res => {
					if (res.data.code == 200) {
						this.env_list = res.data.data.list.filter(item => item.bind_status);
					} else {
						this.$message.error(res.data.msg);
					}
				})
				.finally(() => {});
		},
	},
};
</script>
<style lang="less" scoped>
::v-deep .ant-modal {
	.ant-modal-content {
		.ant-modal-title {
			font-weight: bold;
			font-size: 18px;
		}
		.ant-modal-body {
			.content {
				b {
					display: block;
				}
				.ant-select {
					display: block;
					margin-top: 8px;
				}
			}
		}
	}
}
</style>
