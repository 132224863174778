export const all = [
	{
		type: "newPage",
		config: {},
	},
	{
		type: "closePage",
		config: {},
	},
	{
		type: "closeOtherPage",
		config: {},
	},
	{
		type: "switchPage",
		config: {
			type: "url",
			relation: "equal",
			content: "",
			remark: "",
		},
	},
	{
		type: "gotoUrl",
		config: {
			url: "",
			timeout: 30000,
			remark: "",
		},
	},
	{
		type: "refreshPage",
		config: {
			timeout: 30000,
			remark: "",
		},
	},
	{
		type: "goBack",
		config: {
			timeout: 30000,
			remark: "",
		},
	},
	{
		type: "screenshotPage",
		config: {
			name: "",
			path: "",
			fullPage: "1",
			format: "png",
			quality: 50,
			remark: "",
		},
	},
	{
		type: "passingElement",
		config: {
			selector: "",
			selectorType: "selector",
			element: "",
			serialType: "fixedValue",
			serial: 1,
			serialMin: 1,
			serialMax: 50,
			remark: "",
		},
	},
	{
		type: "selectElement",
		config: {
			selector: "",
			selectorType: "selector",
			element: "",
			value: "",
			serialType: "fixedValue",
			serial: 1,
			serialMin: 1,
			serialMax: 50,
			remark: "",
		},
	},
	{
		type: "focusElement",
		config: {
			selector: "",
			selectorType: "selector",
			element: "",
			serialType: "fixedValue",
			serial: 1,
			serialMin: 1,
			serialMax: 50,
			remark: "",
		},
	},
	{
		type: "click",
		config: {
			selector: "",
			selectorType: "selector",
			element: "",
			serialType: "fixedValue",
			serial: 1,
			serialMin: 1,
			serialMax: 50,
			button: "left",
			type: "click",
			remark: "",
		},
	},
	{
		type: "inputContent",
		config: {
			selector: "",
			selectorType: "selector",
			element: "",
			serialType: "fixedValue",
			serial: 1,
			serialMin: 1,
			serialMax: 50,
			intervals: 300,
			content: "",
			isRandom: "1",
			randomContent: "",
			remark: "",
		},
	},
	{
		type: "scrollPage",
		config: {
			distance: 1,
			type: "smooth",
			scrollType: "position",
			position: "bottom",
			remark: "",
		},
	},
	{
		type: "uploadAttachment",
		config: {
			selector: "",
			selectorType: "selector",
			element: "",
			serialType: "fixedValue",
			serial: 1,
			type: "local",
			url: "",
			timeout: 30000,
			remark: "",
		},
	},
	{
		type: "javaScript",
		config: {
			params: [],
			content: "",
			variable: "",
			remark: "",
		},
	},
	{
		type: "mouseClick",
		vers: {
			windows: {
				vCode: 2000105,
			},
			macosx: {
				arm64: {
					vCode: 1,
				},
				intel: {
					vCode: 1,
				},
			},
		},
		config: {
			button: "left",
			type: "click",
			x: 0,
			y: 0,
			remark: "",
		},
	},
	{
		type: "mouseMove",
		vers: {
			windows: {
				vCode: 2000105,
			},
			macosx: {
				arm64: {
					vCode: 1,
				},
				intel: {
					vCode: 1,
				},
			},
		},
		config: {
			type: "smooth",
			x: 0,
			y: 0,
			remark: "",
		},
	},
	{
		type: "keyboard",
		config: {
			type: "Enter",
			remark: "",
		},
	},
	{
		type: "keyCombination",
		vers: {
			windows: {
				vCode: 2000106,
			},
			macosx: {
				arm64: {
					vCode: 1,
				},
				intel: {
					vCode: 1,
				},
			},
		},
		config: {
			command: "",
			remark: "",
		},
	},
	{
		type: "waitTime",
		config: {
			timeoutType: "fixedValue",
			timeout: 30000,
			timeoutMin: 1000,
			timeoutMax: 30000,
			remark: "",
		},
	},
	{
		type: "waitForSelector",
		config: {
			selector: "",
			serial: 1,
			isShow: "1",
			timeout: 30000,
			remark: "",
		},
	},
	{
		type: "waitForResponse",
		config: {
			url: "",
			timeout: 30000,
			remark: "",
		},
	},
	{
		type: "getUrl",
		config: {
			type: "href",
			key: "",
			variable: "",
			remark: "",
		},
	},
	{
		type: "getElement",
		config: {
			selector: "",
			serial: 1,
			selectorType: "selector",
			serialType: "fixedValue",
			serialMin: 1,
			serialMax: 50,
			type: "innerText",
			element: "",
			key: "",
			variable: "",
			remark: "",
		},
	},
	{
		type: "getActiveElement",
		config: {
			variable: "",
			remark: "",
		},
	},
	{
		type: "saveData",
		config: {
			name: "",
			template: "",
			remark: "",
		},
	},
	{
		type: "exportExcel",
		config: {
			name: "",
			fields: [],
			remark: "",
		},
	},
	{
		type: "downloadFile",
		config: {
			url: "",
			path: "",
			remark: "",
		},
	},
	{
		type: "useExcel",
		config: {
			path: "",
			variableList: [],
			variable: "",
			remark: "",
		},
	},
	{
		type: "importText",
		config: {
			path: "",
			variable: "",
			remark: "",
		},
	},
	{
		type: "getRequest",
		config: {
			url: "",
			type: "url",
			key: "",
			variable: "",
			remark: "",
		},
	},
	{
		type: "getResponse",
		config: {
			url: "",
			variable: "",
			remark: "",
		},
	},
	{
		type: "stopLinsten",
		config: {},
	},
	{
		type: "extractData",
		config: {
			content: "",
			reg: "",
			notUpper: 0,
			variable: "",
			remark: "",
		},
	},
	{
		type: "toJson",
		config: {
			content: "",
			variable: "",
			remark: "",
		},
	},
	{
		type: "extractKey",
		config: {
			content: "",
			key: "",
			variable: "",
			remark: "",
		},
	},
	{
		type: "randomGet",
		config: {
			content: "",
			variable: "",
			remark: "",
		},
	},
	{
		type: "ifElse",
		config: {
			condition: "",
			relation: "exist",
			result: "",
			children: [],
			remark: "",
			other: [],
		},
	},
	{
		type: "forElements",
		config: {
			selector: "",
			type: "innerText",
			key: "",
			variableIndex: "for_elements_index",
			variable: "for_elements_item",
			remark: "",
			children: [],
		},
	},
	{
		type: "forTimes",
		config: {
			times: 10,
			variableIndex: "for_times_index",
			remark: "",
			children: [],
		},
	},
	{
		type: "forLists",
		config: {
			content: "",
			variableIndex: "for_lits_index",
			variable: "for_lits_item",
			remark: "",
			children: [],
		},
	},
	{
		type: "breakLoop",
		config: {},
	},
	{
		type: "closeBrowser",
		config: {},
	},
];
export const data_type_map = [
	{
		name: "执行JS脚本",
		type: "javaScript",
		data_type: "任意",
	},
	{
		name: "获取URL",
		type: "getUrl",
		data_type: "字符",
	},
	{
		name: "元素数据",
		type: "getElement",
		data_type: "字符",
	},
	{
		name: "当前焦点元素",
		type: "getActiveElement",
		data_type: "元素对象",
	},
	{
		name: "导入Excel素材",
		type: "useExcel",
		data_type: "数组",
	},
	{
		name: "导入txt",
		type: "importText",
		data_type: "数组",
	},
	{
		name: "监听请求触发",
		type: "getRequest",
		data_type: "字符",
	},
	{
		name: "监听请求结果",
		type: "getResponse",
		data_type: "字符",
	},
	{
		name: "文本中提取",
		type: "extractData",
		data_type: "字符",
	},
	{
		name: "转换Json对象",
		type: "toJson",
		data_type: "对象",
	},
	{
		name: "字段提取",
		type: "extractKey",
		data_type: "任意",
	},
	{
		name: "随机提取",
		type: "randomGet",
		data_type: "任意",
	},
	{
		name: "For循环元素",
		type: "forElements",
		data_type: "字符",
	},
	{
		name: "For循环数据",
		type: "forLists",
		data_type: "任意",
	},
];

const useVariable = [
	{
		name: "切换标签",
		desc: "全部变量",
	},
	{
		name: "访问网站",
		desc: "全部变量",
	},
	{
		name: "经过元素",
		desc: "储存的元素对象",
	},
	{
		name: "下拉选择器",
		desc: "储存的元素对象",
	},
	{
		name: "元素聚焦",
		desc: "储存的元素对象",
	},
	{
		name: "点击元素",
		desc: "储存的元素对象",
	},
	{
		name: "输入内容",
		desc: "储存的元素对象",
	},
	{
		name: "上传附件",
		desc: "储存的元素对象",
	},
	{
		name: "执行JS脚本",
		desc: "全部变量",
	},
	{
		name: "元素数据",
		desc: "储存的元素对象",
	},
	{
		name: "存到文件",
		desc: "全部变量",
	},
	{
		name: "存到Excel",
		desc: "全部变量",
	},
	{
		name: "文本中提取",
		desc: "全部变量",
	},
	{
		name: "转换Json对象",
		desc: "全部变量",
	},
	{
		name: "字段提取",
		desc: "转换Json对象的结果+导入excel的结果+导入txt的结果+字段提取结果+随机提取结果",
	},
	{
		name: "随机提取",
		desc: "转换Json对象的结果+导入excel的结果+导入txt的结果+字段提取结果+随机提取结果",
	},
	{
		name: "IF条件",
		desc: "全部变量",
	},
	{
		name: "For循环数据",
		desc: "转换Json对象的结果+导入excel的结果+导入txt的结果+字段提取结果+随机提取结果",
	},
];

export const tab_type_options = [
	{
		value: "url",
		label: "URL",
	},
	{
		value: "title",
		label: "标题",
	},
];

export const relation_options = [
	{
		value: "equal",
		label: "等于",
	},
	{
		value: "notEqual",
		label: "不等于",
	},
	{
		value: "contain",
		label: "包含",
	},
	{
		value: "notContain",
		label: "不包含",
	},
];

export const element_order = [
	{
		value: "fixedValue",
		label: "固定值",
	},
	{
		value: "randomInterval",
		label: "区间随机",
	},
];

export const scroll_position = [
	{
		value: "top",
		label: "顶部",
	},
	{
		value: "middle",
		label: "中间",
	},
	{
		value: "bottom",
		label: "底部",
	},
];

export const options_list = [
	[
		{ name: "新建标签页", type: "newPage", category: "页面操作", config: {} },
		{ name: "关闭标签", type: "closePage", category: "页面操作", config: {} },
		{ name: "关闭其他标签", type: "closeOtherPage", category: "页面操作", config: {} },
		{ name: "切换标签", type: "switchPage", category: "页面操作", config: { type: "url", relation: "equal", content: "", remark: "" } },
		{ name: "访问网站", type: "gotoUrl", category: "页面操作", config: { url: "", timeout: 30000, remark: "" } },
		{ name: "刷新页面", type: "refreshPage", category: "页面操作", config: { timeout: 30000, remark: "" } },
		{ name: "页面后退", type: "goBack", category: "页面操作", config: { timeout: 30000, remark: "" } },
		{
			name: "页面截图",
			type: "screenshotPage",
			category: "页面操作",
			config: { name: "", path: "", fullPage: "1", format: "png", quality: 50, remark: "" },
		},
		{
			name: "经过元素",
			type: "passingElement",
			category: "页面操作",
			config: { selector: "input", selectorType: "selector", element: "", serialType: "fixedValue", serial: 1, serialMin: 1, serialMax: 50, remark: "" },
		},
		{
			name: "下拉选择器",
			type: "selectElement",
			category: "页面操作",
			config: {
				selector: "",
				selectorType: "selector",
				element: "",
				value: "",
				serialType: "fixedValue",
				serial: 1,
				serialMin: 1,
				serialMax: 50,
				remark: "",
			},
		},
		{
			name: "元素聚焦",
			type: "focusElement",
			category: "页面操作",
			config: { selector: "input", selectorType: "selector", element: "", serialType: "fixedValue", serial: 1, serialMin: 1, serialMax: 50, remark: "" },
		},
		{
			name: "点击元素",
			type: "click",
			category: "页面操作",
			config: {
				selector: "",
				selectorType: "selector",
				element: "",
				serialType: "fixedValue",
				serial: 1,
				serialMin: 1,
				serialMax: 50,
				button: "left",
				type: "click",
				remark: "",
			},
		},
		{
			name: "输入内容",
			type: "inputContent",
			category: "页面操作",
			config: {
				selector: "",
				selectorType: "selector",
				element: "",
				serialType: "fixedValue",
				serial: 1,
				serialMin: 1,
				serialMax: 50,
				intervals: 300,
				content: "",
				isRandom: "0",
				randomContent: "",
				remark: "",
			},
		},
		{
			name: "滚动页面",
			type: "scrollPage",
			category: "页面操作",
			config: { distance: 0, type: "smooth", scrollType: "position", position: "bottom", remark: "" },
		},
		{
			name: "上传附件",
			type: "uploadAttachment",
			category: "页面操作",
			config: {
				selector: "",
				selectorType: "selector",
				element: "",
				serialType: "fixedValue",
				serial: 1,
				type: "local",
				url: "",
				timeout: 30000,
				remark: "",
			},
		},
		{ name: "执行JS脚本", type: "javaScript", category: "页面操作", config: { params: [], content: "", variable: "", remark: "" } },
	],
	[
		{
			name: "鼠标点击",
			type: "mouseClick",
			category: "鼠标键盘",
			vers: { windows: { vCode: 2000105 }, macosx: { arm64: { vCode: 1 }, intel: { vCode: 1 } } },
			config: { button: "left", type: "click", x: 0, y: 0, remark: "" },
		},
		{
			name: "鼠标移动",
			type: "mouseMove",
			category: "鼠标键盘",
			vers: { windows: { vCode: 2000105 }, macosx: { arm64: { vCode: 1 }, intel: { vCode: 1 } } },
			config: { type: "smooth", x: 0, y: 0, remark: "" },
		},
		{ name: "键盘按键", type: "keyboard", category: "鼠标键盘", config: { type: "Enter", remark: "" } },
		{
			name: "键盘组合键",
			type: "keyCombination",
			category: "鼠标键盘",
			vers: { windows: { vCode: 2000106 }, macosx: { arm64: { vCode: 1 }, intel: { vCode: 1 } } },
			config: { command: "", remark: "" },
		},
	],
	[
		{
			name: "等待时间",
			type: "waitTime",
			category: "等待操作",
			config: { timeoutType: "fixedValue", timeout: 30000, timeoutMin: 1000, timeoutMax: 30000, remark: "" },
		},
		{
			name: "等待元素出现",
			type: "waitForSelector",
			category: "等待操作",
			config: { selector: "input", serial: 1, isShow: "1", timeout: 30000, remark: "" },
		},
		{ name: "等待请求完成", type: "waitForResponse", category: "等待操作", config: { url: "", timeout: 30000, remark: "" } },
	],
	[
		{ name: "获取URL", type: "getUrl", category: "获取数据", config: { type: "href", key: "", variable: "", remark: "" } },
		{
			name: "元素数据",
			type: "getElement",
			category: "获取数据",
			config: {
				selector: "input",
				serial: 1,
				selectorType: "selector",
				serialType: "fixedValue",
				serialMin: 1,
				serialMax: 50,
				type: "innerText",
				element: "",
				key: "",
				variable: "",
				remark: "",
			},
		},
		{ name: "当前焦点元素", type: "getActiveElement", category: "获取数据", config: { variable: "", remark: "" } },
		{ name: "存到文件", type: "saveData", category: "获取数据", config: { name: "", template: "", remark: "" } },
		{ name: "存到Excel", type: "exportExcel", category: "获取数据", config: { name: "", fields: [], remark: "" } },
		{ name: "下载文件", type: "downloadFile", category: "获取数据", config: { url: "", path: "", remark: "" } },
		{ name: "导入Excel素材", type: "useExcel", category: "获取数据", config: { path: "", variableList: [], variable: "", remark: "" } },
		{ name: "导入txt", type: "importText", category: "获取数据", config: { path: "", variable: "", remark: "" } },
		{ name: "监听请求触发", type: "getRequest", category: "获取数据", config: { url: "", type: "url", key: "", variable: "", remark: "" } },
		{ name: "监听请求结果", type: "getResponse", category: "获取数据", config: { url: "", variable: "", remark: "" } },
		{ name: "停止页面监听", type: "stopLinsten", category: "获取数据", config: {} },
	],
	[
		{ name: "文本中提取", type: "extractData", category: "数据处理", config: { content: "task_id", reg: "", notUpper: 0, variable: "", remark: "" } },
		{ name: "转换Json对象", type: "toJson", category: "数据处理", config: { content: "task_id", variable: "", remark: "" } },
		{ name: "字段提取", type: "extractKey", category: "数据处理", config: { content: "", key: "", variable: "", remark: "" } },
		{ name: "随机提取", type: "randomGet", category: "数据处理", config: { content: "", variable: "", remark: "" } },
	],
	[
		{ name: "IF条件", type: "ifElse", category: "流程管理", config: { condition: "${task_id}", relation: "exist", result: "", children: [], remark: "" } },
		{
			name: "For循环元素",
			type: "forElements",
			category: "流程管理",
			config: {
				selector: "input",
				type: "innerText",
				key: "",
				variableIndex: "for_elements_index",
				variable: "for_elements_item",
				remark: "",
				children: [],
			},
		},
		{ name: "For循环次数", type: "forTimes", category: "流程管理", config: { times: 10, variableIndex: "for_times_index", remark: "", children: [] } },
		{
			name: "For循环数据",
			type: "forLists",
			category: "流程管理",
			config: { content: "for_lits_item", variableIndex: "for_lits_index", variable: "for_lits_item", remark: "", children: [] },
		},
		{ name: "退出循环", type: "breakLoop", category: "流程管理", config: {} },
		{ name: "关闭浏览器", type: "closeBrowser", category: "流程管理", config: {} },
	],
];

export const env_variables = [
	{
		value: "task_id",
		label: "<任务ID><字符>",
	},
	{
		value: "task_name",
		label: "<任务名称><字符>",
	},
	{
		value: "serial_number",
		label: "<账号序号><字符>",
	},
	{
		value: "acc_id",
		label: "<账号ID><字符>",
	},
	{
		value: "comment",
		label: "<环境备注><字符>",
	},
	{
		value: "user_name",
		label: "<平台账户><字符>",
	},
	{
		value: "password",
		label: "<平台密码><字符>",
	},
	{
		value: "cookies",
		label: "<环境cookies><字符>",
	},
];

export const operate_name = {
	newPage: "新建标签页",
	closePage: "关闭标签",
	closeOtherPage: "关闭其他标签",
	switchPage: "切换标签",
	gotoUrl: "访问网站",
	refreshPage: "刷新页面",
	goBack: "页面后退",
	screenshotPage: "页面截图",
	passingElement: "经过元素",
	selectElement: "下拉选择器",
	focusElement: "元素聚焦",
	click: "点击元素",
	inputContent: "输入内容",
	scrollPage: "滚动页面",
	uploadAttachment: "上传附件",
	javaScript: "执行JS脚本",
	mouseClick: "鼠标点击",
	mouseMove: "鼠标移动",
	keyboard: "键盘按键",
	keyCombination: "键盘组合键",
	waitTime: "等待时间",
	waitForSelector: "等待元素出现",
	waitForResponse: "等待请求完成",
	getUrl: "获取URL",
	getElement: "元素数据",
	getActiveElement: "当前焦点元素",
	saveData: "存到文件",
	exportExcel: "存到Excel",
	downloadFile: "下载文件",
	useExcel: "导入Excel素材",
	importText: "导入txt",
	getRequest: "监听请求触发",
	getResponse: "监听请求结果",
	stopLinsten: "停止页面监听",
	extractData: "文本中提取",
	toJson: "转换Json对象",
	extractKey: "字段提取",
	randomGet: "随机提取",
	ifElse: "IF条件",
	forElements: "For循环元素",
	forTimes: "For循环次数",
	forLists: "For循环数据",
	breakLoop: "退出循环",
	closeBrowser: "关闭浏览器",
};

export const click_button = [
	{
		value: "left",
		label: "左键",
	},
	{
		value: "middle",
		label: "中键",
	},
	{
		value: "right",
		label: "右键",
	},
];
export const click_type = [
	{
		value: "click",
		label: "单击",
	},
	{
		value: "dblclick",
		label: "双击",
	},
];

export const form_rules = {
	url: [
		{
			required: true,
			message: "请输入URL",
			trigger: "blur",
		},
	],
	timeout: [
		{
			required: true,
			message: "请输入超时等待时间",
			trigger: "blur",
		},
	],
	selector: [
		{
			required: true,
			message: "请输入元素选择器",
			trigger: "blur",
		},
	],
	element: [
		{
			required: true,
			message: "请选择已储存的元素对象",
			trigger: "blur",
		},
	],
	value: [
		{
			required: true,
			message: "请填写选择的值",
			trigger: "blur",
		},
	],
	intervals: [
		{
			required: true,
			message: "请输入间隔时间",
			trigger: "blur",
		},
	],
	randomContent: [
		{
			required: true,
			message: "请输入内容",
			trigger: "blur",
		},
		{
			validator: (rule, value, callback) => {
				let arr = value.split("\n");
				if (arr.length > 50) {
					callback(`内容最多50行，当前内容${arr.length}行`);
				} else if (arr.some(item => item.length > 500)) {
					callback("每行内容最多500个字符");
				} else {
					callback();
				}
			},
		},
	],
	command: [
		{
			required: true,
			message: "请选择组合键",
			trigger: "blur",
		},
	],
	name: [
		{
			required: true,
			message: "请输入文件名",
			trigger: "blur",
		},
	],

	template: [
		{
			required: true,
			message: "请输入保存的信息和获取数据的变量",
			trigger: "blur",
		},
	],
	path: [
		{
			required: true,
			message: "请输入路径",
			trigger: "blur",
		},
	],
	condition: [
		{
			required: true,
			message: "请选择变量",
			trigger: "blur",
		},
	],
};

export const keyboard_list = [
	{
		label: "退格键",
		value: "Backspace",
	},
	{
		label: "Tab键",
		value: "Tab",
	},
	{
		label: "回车键",
		value: "Enter",
	},
	{
		label: "空格键",
		value: "Space",
	},
	{
		label: "Esc键",
		value: "Escape",
	},
	{
		label: "删除键",
		value: "Delete",
	},
	{
		label: "方向上键",
		value: "ArrowUp",
	},
	{
		label: "方向下键",
		value: "ArrowDown",
	},
	{
		label: "方向左键",
		value: "ArrowLeft",
	},
	{
		label: "方向右键",
		value: "ArrowRight",
	},
	{
		label: "上页键",
		value: "PageUp",
	},
	{
		label: "下页键",
		value: "PageDown",
	},
];

export const command_list = [
	{
		label: "Ctrl+A (⌘A)",
		value: "ControlLeft+KeyA",
	},
	{
		label: "Ctrl+C (⌘C)",
		value: "ControlLeft+KeyC",
	},
	{
		label: "Ctrl+V (⌘V)",
		value: "ControlLeft+KeyV",
	},
	{
		label: "Ctrl+R (⌘R)",
		value: "ControlLeft+KeyR",
	},
];
export const extraction_type_list = [
	{
		label: "文本",
		value: "innerText",
	},
	{
		label: "对象",
		value: "object",
	},
	{
		label: "iFrame框架",
		value: "contentFrame",
	},
	{
		label: "源码",
		value: "innerHTML",
	},
	{
		label: "属性",
		value: "attribute",
	},
	{
		label: "子元素",
		value: "childrenNode",
	},
];

export const request_extraction_type_list = [
	{
		label: "完整URL",
		value: "url",
	},
	{
		label: "请求头",
		value: "headers",
	},
	{
		label: "Get参数",
		value: "getParams",
	},
	{
		label: "Post数据",
		value: "postData",
	},
];

export const getUrl_options = [
	{
		value: "href",
		label: "完整地址",
	},
	{
		value: "origin",
		label: "根地址",
	},
	{
		value: "search",
		label: "参数值",
	},
];

export const if_relation = [
	{
		label: "存在",
		value: "exist",
	},
	{
		label: "不存在",
		value: "notExist",
	},
	{
		label: "小于",
		value: "less",
	},
	{
		label: "小于等于",
		value: "lessEqual",
	},
	{
		label: "等于",
		value: "equal",
	},
	{
		label: "不等于",
		value: "notEqual",
	},
	{
		label: "大于",
		value: "more",
	},
	{
		label: "大于等于",
		value: "moreEqual",
	},
	{
		label: "包含",
		value: "contain",
	},
	{
		label: "不包含",
		value: "notContain",
	},
	{
		label: "在其中",
		value: "oneOf",
	},
	{
		label: "不在其中",
		value: "notOneOf",
	},
];

// 任务说明
export const taskDescription = {
	switchPage: "切换到 <span>_PLACEHOLDER_</span> <span>_PLACEHOLDER_</span> <span>_PLACEHOLDER_</span> 的标签",
	gotoUrl: "访问URL <span>_PLACEHOLDER_</span>, 超时等待 <span>_PLACEHOLDER_</span> 毫秒",
	refreshPage: "刷新当前页面, 超时等待 <span>_PLACEHOLDER_</span> 毫秒",
	goBack: "页面后退, 超时等待 <span>_PLACEHOLDER_</span> 毫秒",
	screenshotPage: "截图名称 <span>_PLACEHOLDER_</span>, 截全屏 <span>_PLACEHOLDER_</span>, 图片格式 <span>_PLACEHOLDER_</span>",
	passingElement: "_PLACEHOLDER_: <span>_PLACEHOLDER_</span> _PLACEHOLDER_ <span>_PLACEHOLDER_</span>",
	selectElement: "_PLACEHOLDER_: <span>_PLACEHOLDER_</span> _PLACEHOLDER_ <span>_PLACEHOLDER_</span>, 选择的值 <span>_PLACEHOLDER_</span>",
	focusElement: "_PLACEHOLDER_: <span>_PLACEHOLDER_</span> _PLACEHOLDER_ <span>_PLACEHOLDER_</span>",
	click: "_PLACEHOLDER_: <span>_PLACEHOLDER_</span> _PLACEHOLDER_ <span>_PLACEHOLDER_</span>, 点击类型 <span>_PLACEHOLDER_</span>, 按键 <span>_PLACEHOLDER_</span>",
	inputContent:
		"_PLACEHOLDER_: <span>_PLACEHOLDER_</span> _PLACEHOLDER_ <span>_PLACEHOLDER_</span>, 输入间隔时间 <span>_PLACEHOLDER_</span> 毫秒, 内容 <span>_PLACEHOLDER_</span>",
	scrollPage: "_PLACEHOLDER_ <span>_PLACEHOLDER_</span>, 过渡效果 <span>_PLACEHOLDER_<span>",
	uploadAttachment:
		"_PLACEHOLDER_ <span>_PLACEHOLDER_</span>, _PLACEHOLDER_ <span>_PLACEHOLDER_</span>, 附件 <span>_PLACEHOLDER_</span>, 超时等待 <span>_PLACEHOLDER_</span> 毫秒",
	javaScript: "在网页上执行JavaScript脚本, _PLACEHOLDER_ , _PLACEHOLDER_",
	mouseClick: "点击类型 <span>_PLACEHOLDER_</span>, 按键 <span>_PLACEHOLDER_</span>, 位置坐标<span>_PLACEHOLDER_</span>",
	mouseMove: "过度效果: <span>_PLACEHOLDER_</span>, 移动至 <span>_PLACEHOLDER_</span>",
	keyboard: "键盘按键 <span>_PLACEHOLDER_</span>",
	keyCombination: "组合键 <span>_PLACEHOLDER_</span>",
	waitTime: "等待 <span>_PLACEHOLDER_</span> 毫秒",
	waitForSelector:
		"选择器: <span>_PLACEHOLDER_</span>, 元素顺序 <span>_PLACEHOLDER_</span>, 超时等待 <span>_PLACEHOLDER_</span>, 是否可见 <span>_PLACEHOLDER_</span>",
	waitForResponse: "等待URL <span>_PLACEHOLDER_</span> 请求完成, 超时等待 <span>_PLACEHOLDER_</span> 毫秒",
	getUrl: "获取当前URL的 <span>_PLACEHOLDER_</span>, _PLACEHOLDER_ _PLACEHOLDER_",
	getElement:
		"_PLACEHOLDER_: <span>_PLACEHOLDER_</span> _PLACEHOLDER_ <span>_PLACEHOLDER_</span> 提取类型: <span>_PLACEHOLDER_</span> _PLACEHOLDER_ <span>_PLACEHOLDER_</span> _PLACEHOLDER_ ",
	getActiveElement: "获取页面上当前获得焦点的元素, 保存到 <span>_PLACEHOLDER_</span>",
	saveData: "保存到文件: <span>_PLACEHOLDER_-任务ID.txt</span>",
	exportExcel: "保存到文件: <span>_PLACEHOLDER_-任务ID.txt</span>, 保存列 <span>_PLACEHOLDER_</span>",
	downloadFile: "文件地址: <span>_PLACEHOLDER_</span>, 保存至: <span>_PLACEHOLDER_</span>",
	useExcel: "导入本地Excel文件: <span>_PLACEHOLDER_</span>, 数据保存至: <span>_PLACEHOLDER_</span>",
	importText: "导入本地Txt文件: <span>_PLACEHOLDER_</span>, 数据保存至: <span>_PLACEHOLDER_</span>",
	getRequest: "监听页面请求的URL存在: <span>_PLACEHOLDER_</span>, 获取 <span>_PLACEHOLDER_</span> _PLACEHOLDER_ 保存到 <span>_PLACEHOLDER_</span>",
	getResponse: "监听页面请求的URL存在: <span>_PLACEHOLDER_</span>, 获取响应结果保存至 <span>_PLACEHOLDER_</span>",
	extractData: "使用正则表达式: <span>_PLACEHOLDER_</span>, 从变量 <span>_PLACEHOLDER_</span> 中提取内容, 保存至 <span>_PLACEHOLDER_</span>",
	toJson: "将变量 <span>_PLACEHOLDER_</span> 的值转为JSON, 保存至 <span>_PLACEHOLDER_</span>",
	extractKey: "从变量 <span>_PLACEHOLDER_</span> 的值中提取 <span>_PLACEHOLDER_</span>, 保存至 <span>_PLACEHOLDER_</span>",
	randomGet: "从变量 <span>_PLACEHOLDER_</span> 的值中随机提取, 保存至 <span>_PLACEHOLDER_</span>",
	ifElse: "如果变量 <span>_PLACEHOLDER_</span>   <span>_PLACEHOLDER_</span>  <span>_PLACEHOLDER_</span>",
	forElements:
		"选择器 <span>_PLACEHOLDER_</span>, 提取类型: <span>_PLACEHOLDER_</span>, _PLACEHOLDER_ , 循环对象保存至 <span>_PLACEHOLDER_</span>, 循环位置保存至 <span>_PLACEHOLDER_</span>",
	forTimes: "循环 <span>_PLACEHOLDER_</span> 次, 循环位置保存至 <span>_PLACEHOLDER_</span> ",
	forLists: "循环数据 <span>_PLACEHOLDER_</span>, 循环对象保存至 <span>_PLACEHOLDER_</span>, 循环位置保存至 <span>_PLACEHOLDER_</span>",
};
