<template>
	<div class="rpa-create">
		<div class="left">
			<h2>操作选项</h2>
			<div class="menu">
				<div class="categoty" v-for="(cate, index) in category" :key="'cate-' + index" :class="{ active: active_category.includes(cate) }">
					<div class="title" @click="changeMenuStatus(cate)">
						<img :src="require(`@/assets/img/rpa/create/category_icon${index + 1}.svg`)" alt="" />
						<span>{{ cate }}</span>
						<img class="icon_arrow" src="@/assets/img/rpa/create/icon_arrow.svg" alt="" />
					</div>
					<Draggable
						class="dragArea list-group"
						:list="options_list[index]"
						ghost-class="ghost"
						:sort="false"
						:forceFallback="true"
						:group="{ name: 'people', pull: 'clone', put: false }"
						v-height
						:clone="clone">
						<div class="operate-draggableItem" v-for="item in options_list[index]" :key="item.name">
							<span>
								{{ item.name }}
							</span>
							<svg
								t="1708594688498"
								class="icon"
								viewBox="0 0 1024 1024"
								version="1.1"
								xmlns="http://www.w3.org/2000/svg"
								p-id="6816"
								width="20"
								height="20"
								@click="clickAdd(item.type)">
								<path
									d="M512 64C264.55 64 64 264.55 64 512s200.55 448.05 448 448.05 448.05-200.6 448.05-448S759.45 64 512 64z m0 816c-203.26 0-368-164.78-368-368s164.74-368 368-368 368 164.74 368 368-164.74 368-368 368z"
									fill="currentColor"
									p-id="6817"></path>
								<path
									d="M728 467.09H547.1V296a40 40 0 1 0-80 0v171.09H296a40 40 0 1 0 0 80h171.09V728a40 40 0 1 0 80 0V547.1H728a40 40 0 1 0 0-80z"
									fill="currentColor"
									p-id="6818"></path>
							</svg>
						</div>
					</Draggable>
				</div>
			</div>
		</div>
		<div class="right">
			<div class="top">
				<h2 class="left">任务流程({{ taskAmount }})</h2>
				<div class="right">
					<a-button type="primary" ghost :disabled="!taskAmount" @click="run()">
						<svg
							t="1708655560711"
							class="icon"
							viewBox="0 0 1024 1024"
							version="1.1"
							xmlns="http://www.w3.org/2000/svg"
							p-id="5089"
							width="20"
							height="20">
							<path
								d="M489.23 81.63A160 160 0 0 0 352 240v21.34h320V240A160 160 0 0 0 489.23 81.63z m383.76 143.64a32.004 32.004 0 0 0-31.1 7.56L750.62 320H273.38l-91.3-87.14a31.984 31.984 0 1 0-44.16 46.28l86.08 82.2v177.35H96a32 32 0 0 0 0 64h128V640c0 52.96 14.302 102.596 39.23 145.22L137.92 904.86a32.005 32.005 0 1 0 44.19 46.31L301.82 836.9a287.104 287.104 0 0 0 178.21 89.34V544a32 32 0 0 1 64 0v382.24a287.424 287.424 0 0 0 178.21-89.34l119.71 114.27a32.032 32.032 0 0 0 44.19-46.31L760.8 785.22A287.04 287.04 0 0 0 800 640v-37.34h128a32 32 0 0 0 0-64H800V361.31l86.08-82.17a32.004 32.004 0 0 0-13.09-53.87z"
								p-id="5090"
								fill="currentColor"></path>
						</svg>
						<span>调试</span>
					</a-button>
					<a-button type="primary" ghost @click="importDialogVisible = true">
						<svg
							t="1708656007623"
							class="icon"
							viewBox="0 0 1024 1024"
							version="1.1"
							xmlns="http://www.w3.org/2000/svg"
							p-id="12891"
							width="18"
							height="18">
							<path
								d="M0 841.264833m66.698336 0l889.920264 0q66.698336 0 66.698336 66.698336l0 49.338495q0 66.698336-66.698336 66.698336l-889.920264 0q-66.698336 0-66.698336-66.698336l0-49.338495q0-66.698336 66.698336-66.698336Z"
								fill="currentColor"
								p-id="12892"></path>
							<path
								d="M571.412868 698.000462L798.18721 420.973948a19.187193 19.187193 0 0 0-14.984284-31.430448h-99.590666C683.61226 66.102254 395.987107 8.357941 240.84495 0.683064a18.273517 18.273517 0 0 0-10.050434 36.547033c115.30589 61.947222 166.106267 167.202678 172.136528 332.212534A19.187193 19.187193 0 0 1 383.743851 388.812559h-79.672533a19.187193 19.187193 0 0 0-14.801548 32.161389l226.774342 277.757455a36.547033 36.547033 0 0 0 55.368756-0.730941z"
								fill="currentColor"
								p-id="12893"></path>
						</svg>
						<span>导入</span>
					</a-button>
					<a-button type="primary" ghost :disabled="!taskAmount" @click="exportTask">
						<svg
							t="1708656043584"
							class="icon"
							viewBox="0 0 1024 1024"
							version="1.1"
							xmlns="http://www.w3.org/2000/svg"
							p-id="13985"
							width="18"
							height="18">
							<path
								d="M0 841.142857m66.742857 0l890.514286 0q66.742857 0 66.742857 66.742857l0 49.371429q0 66.742857-66.742857 66.742857l-890.514286 0q-66.742857 0-66.742857-66.742857l0-49.371429q0-66.742857 66.742857-66.742857Z"
								fill="currentColor"
								p-id="13986"></path>
							<path
								d="M900.937143 249.234286L600.137143 3.84a16.457143 16.457143 0 0 0-26.88 12.617143v91.428571a16.64 16.64 0 0 1-14.994286 16.274286c-389.485714 38.4-438.857143 358.4-441.234286 509.805714a16.457143 16.457143 0 0 0 31.268572 7.314286c73.142857-150.674286 227.84-230.4 407.771428-237.714286a16.64 16.64 0 0 1 17.188572 16.64v88.137143a16.457143 16.457143 0 0 0 26.88 12.8L900.937143 274.285714a16.64 16.64 0 0 0 0-25.051428z"
								fill="currentColor"
								p-id="13987"></path>
						</svg>
						<span>导出</span>
					</a-button>
				</div>
			</div>
			<main>
				<div class="config">
					<a-form-model layout="inline" :model="taskConfig">
						<a-form-model-item label="任务名称">
							<a-input v-model="taskConfig.name" placeholder="请填写任务名称" :maxLength="100">
								<span slot="suffix">{{ taskConfig.name.length }}/100</span>
							</a-input>
						</a-form-model-item>
						<a-form-model-item label="选择分组">
							<a-select v-model="taskConfig.group_id">
								<a-select-option v-for="item in groupList" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
							</a-select>
							<div class="btn_createGroup" @click="createGroupDialogVisible = true">创建分组</div>
						</a-form-model-item>
						<a-form-model-item label="异常处理">
							<a-select v-model="taskConfig.exception">
								<a-select-option value="continue">跳过</a-select-option>
								<a-select-option value="break">中断</a-select-option>
							</a-select>
						</a-form-model-item>
						<a-form-model-item label="任务完成">
							<a-select v-model="taskConfig.finish[0]">
								<a-select-option value="closetabs">清除标签</a-select-option>
								<a-select-option value="keeptabs">保存标签</a-select-option>
							</a-select>
							<a-select v-model="taskConfig.finish[1]">
								<a-select-option value="closebrowser">关闭浏览器</a-select-option>
								<a-select-option value="keepbrowser">保留浏览器</a-select-option>
							</a-select>
						</a-form-model-item>
					</a-form-model>
					<div class="other">
						<a-button type="primary" ghost @click="otherSettingDialogVisible = true">
							<svg
								t="1708660310351"
								class="icon"
								viewBox="0 0 1030 1024"
								version="1.1"
								xmlns="http://www.w3.org/2000/svg"
								p-id="15136"
								width="18"
								height="18">
								<path
									d="M376.053929 561.350639H86.337861A86.440889 86.440889 0 0 0 0 647.6885v289.922125a86.492404 86.492404 0 0 0 86.337861 86.389375H376.053929a86.492404 86.492404 0 0 0 86.389375-86.389375v-289.922125A86.440889 86.440889 0 0 0 376.053929 561.350639z m8.396821 376.053929a8.551363 8.551363 0 0 1-8.396821 8.602877H86.337861a8.499849 8.499849 0 0 1-8.345306-8.39682v-289.922125a8.448335 8.448335 0 0 1 8.345306-8.345307H376.053929a8.499849 8.499849 0 0 1 8.396821 8.345307z"
									p-id="15137"
									fill="currentColor"></path>
								<path
									d="M1018.694034 287.91307l-82.422779-142.488379a38.97052 38.97052 0 1 0-67.483651 38.996277l82.422779 142.488379a8.602878 8.602878 0 0 1-3.090854 11.487675l-251.08039 144.909548a8.087735 8.087735 0 0 1-6.336251 0.824228 8.242278 8.242278 0 0 1-5.151424-3.863568L540.899487 229.18684a8.499849 8.499849 0 0 1 3.090854-11.436161l251.028876-144.961062a38.996277 38.996277 0 0 0-38.944763-67.535165L504.839521 150.215515a85.668176 85.668176 0 0 0-40.284133 52.699064 84.637891 84.637891 0 0 0-1.906027 9.272563V127.90985A86.492404 86.492404 0 0 0 376.053929 41.520475H86.337861A86.440889 86.440889 0 0 0 0 127.90985v289.922125a86.440889 86.440889 0 0 0 86.337861 86.337861H376.053929a86.440889 86.440889 0 0 0 86.595432-86.337861V238.253345a85.822719 85.822719 0 0 0 10.302847 29.929772L618.170842 519.263507a85.513633 85.513633 0 0 0 61.817084 42.087132h-68.616963a86.492404 86.492404 0 0 0-86.389375 86.337861v289.922125a86.543918 86.543918 0 0 0 86.389375 86.389375H901.499145a86.492404 86.492404 0 0 0 86.337861-86.389375v-289.922125A86.440889 86.440889 0 0 0 901.499145 561.350639h-195.187444a85.925747 85.925747 0 0 0 29.723715-10.302847l251.08039-145.16712a86.440889 86.440889 0 0 0 31.578228-117.967602zM384.656807 417.831975A8.499849 8.499849 0 0 1 376.053929 426.177281H86.337861a8.448335 8.448335 0 0 1-8.345306-8.345306V127.90985a8.499849 8.499849 0 0 1 8.345306-8.396821H376.053929a8.551363 8.551363 0 0 1 8.396821 8.396821z m524.981587 229.856525v289.922125a8.499849 8.499849 0 0 1-8.345306 8.39682h-289.922125a8.551363 8.551363 0 0 1-8.396821-8.39682v-289.922125a8.499849 8.499849 0 0 1 8.396821-8.345307H901.499145a8.448335 8.448335 0 0 1 8.139249 8.345307z"
									p-id="15138"
									fill="currentColor"></path>
							</svg>
							<span>其他设置</span>
						</a-button>
					</div>
				</div>
				<div class="tasks">
					<DraggableGroup :tasks="result" />
					<div class="tip_container" v-if="!taskAmount">
						<img src="@/assets/img/rpa/create/empty.svg" alt="" />
						<p>暂无任务流程</p>
						<p class="tip">点击添加/拖拽左侧的操作选项至该区域创建任务流程</p>
					</div>
				</div>
			</main>
			<div class="footer">
				<template v-if="$route.params.id">
					<a-button type="primary" size="large" @click="updateTask" :loading="updateLoading"> 更新 </a-button>
					<a-button size="large" @click="$router.push('/manage/rpa/manage')"> 取消 </a-button>
				</template>
				<template v-else>
					<a-button type="primary" size="large" @click="updateTask" :loading="updateLoading"> 添加 </a-button>
				</template>
			</div>
		</div>
		<div class="config">
			<ConfigDialog
				:visible.sync="configDialog_visible"
				:config="target.config"
				:target="target"
				@confirm="confirmConfig"
				@cancel="cancel"
				:allVariables="allVariables"
				:elementObject="elementObject"
				:objectVariables="objectVariables" />
		</div>
		<a-modal v-model="exportDialogVisible" title="导出" :width="600" :destroyOnClose="true" :maskClosable="false" @ok="exportDialogVisible = false">
			<a-row :gutter="10">
				<a-col :span="4">流程JSON</a-col>
				<a-col :span="20">
					<a-textarea ref="jsonViewer" v-model="resultJSON" readOnly :auto-size="{ minRows: 12, maxRows: 12 }" />
				</a-col>
			</a-row>
		</a-modal>
		<a-modal v-model="importDialogVisible" title="导入" :width="600" :destroyOnClose="true" :maskClosable="false" @ok="importTaskJson">
			<a-row :gutter="10">
				<a-col :span="4">流程JSON</a-col>
				<a-col :span="20">
					<a-textarea v-model="importData" :auto-size="{ minRows: 12, maxRows: 12 }" placeholder="请将要导入的流程JSON粘贴到此处" />
				</a-col>
			</a-row>
		</a-modal>
		<CreateGroup :visible.sync="createGroupDialogVisible" @success="getGroupList" />
		<OtherSetting :config="otherSettings" :visible.sync="otherSettingDialogVisible" @ok="otherSettingConfirm" />
		<SelectEnvironment :visible.sync="selectEnvDialogVisible" @ok="run" />
	</div>
</template>

<script>
import { options_list, all, data_type_map, operate_name } from "@/utils/rpa.js";
import Draggable from "vuedraggable";
import DraggableGroup from "../components/DraggableGroup";
import ConfigDialog from "../components/ConfigDialog.vue";
import CreateGroup from "../components/CreateGroup.vue";
import OtherSetting from "../components/OtherSetting.vue";
import SelectEnvironment from "../components/SelectEnvironment.vue";
import { groupListApi, updateTaskApi, taskDetailApi } from "@/api/rpa";
import RunTask from "@/utils/runTask.js";
import { environment_get_config } from "@/api/environment.js";
import { languageList } from "@/utils/languageList";
export default {
	name: "RpaCreate",
	components: {
		Draggable,
		DraggableGroup,
		ConfigDialog,
		CreateGroup,
		OtherSetting,
		SelectEnvironment,
	},
	data() {
		return {
			operate_name,
			options_list: Object.freeze(options_list),
			category: ["页面操作", "鼠标键盘", "等待操作", "获取数据", "数据处理", "流程管理"], // 左侧任务分类
			active_category: ["页面操作", "鼠标键盘", "等待操作", "获取数据", "数据处理", "流程管理"], // 展开的左侧任务分类
			result: [], // 操作结果
			result_temp: [], // 操作结果临时变量
			// 一般情况下 result 和 result_temp 需要保持一致，但是当添加需要进行配置的任务时，要先改变 result 来更新结果视图，当前任务配置完成点击确定后再让result_temp和result保持一致，当前任务配置点击取消时将result_temp赋值给result来撤销更改
			taskConfig: {
				name: "",
				group_id: "",
				exception: "continue",
				finish: ["closetabs", "closebrowser"],
			}, // 任务流程配置
			otherSettings: {
				remark: "",
				timeout: 0,
				params: "",
			},
			target: {
				config: {},
			}, // 当前操作目标
			configDialog_visible: false, // 配置弹窗是否显示
			operateType: "", // 当前操作类型 -> drag/click/edit
			exportDialogVisible: false,
			resultJSON: "",
			createGroupDialogVisible: false,
			otherSettingDialogVisible: false,
			groupList: [],
			updateLoading: false,
			importDialogVisible: false,
			importData: "",
			selectEnvDialogVisible: false, // 选择环境弹窗是否显示
			runLoading: false,
		};
	},
	watch: {
		// 监听总任务流程数量 -> 添加/删除/复制
		taskAmount(newVal, oldVal) {
			if (oldVal > newVal) {
				// 删除
				this.result_temp = JSON.parse(JSON.stringify(this.result));
			} else {
				// 复制
				if (this.$store.state.isCopy) {
					this.result_temp = JSON.parse(JSON.stringify(this.result));
					return;
				}
				// 添加
				if (this.target.config.hasOwnProperty("remark")) {
					this.configDialog_visible = true;
				} else {
					this.result_temp = JSON.parse(JSON.stringify(this.result));
				}
			}
		},
		// 监听编辑任务流程
		"$store.state.editTask": {
			handler(newVal) {
				if (newVal) {
					this.operateType = "edit";
					this.target = JSON.parse(JSON.stringify(newVal.tasks[newVal.index]));
					this.configDialog_visible = true;
				}
			},
		},
	},
	computed: {
		// 获取流程任务数量
		taskAmount() {
			return this.flatedResult.length;
		},
		// 全部任务流程转为一维数组
		flatedResult() {
			let result = this.flatResult(this.result);
			return result;
		},
		// 变量 - 全部变量
		allVariables() {
			let variable = this.flatedResult
				.filter(item => item.config.variable)
				.map(item => {
					return {
						value: item.config.variable,
						label: `<${this.operate_name[item.type]}><${data_type_map.find(type => type.type === item.type).data_type}>`,
					};
				});
			let variable_index = this.flatedResult
				.filter(item => item.config.variableIndex)
				.map(item => {
					return {
						value: item.config.variableIndex,
						label: `<${this.operate_name[item.type]}><数字>`,
					};
				});
			let list = variable.concat(variable_index);

			return list;
		},
		// 变量 - 元素对象
		elementObject() {
			let result = this.flatedResult
				.filter(item => item.type === "getActiveElement" && item.config.variable)
				.map(item => {
					return {
						value: item.config.variable,
						label: "<当前焦点元素><元素对象>",
					};
				});
			return result;
		},
		// 类型为对象或数组的变量 -> 字段提取、随机提取、For循环数据
		objectVariables() {
			let targetTypes = ["useExcel", "importText", "toJson", "extractKey", "randomGet", "forLists"];
			let result = this.flatedResult
				.filter(item => targetTypes.includes(item.type) && item.config.variable)
				.map(item => {
					return {
						value: item.config.variable,
						label: `<${this.operate_name[item.type]}><${data_type_map.find(type => type.type === item.type).data_type}>`,
					};
				});
			return result;
		},
	},
	methods: {
		// 左侧菜单展开/折叠
		changeMenuStatus(menu) {
			if (this.active_category.includes(menu)) {
				let index = this.active_category.findIndex(item => item === menu);
				this.active_category.splice(index, 1);
			} else {
				this.active_category.push(menu);
			}
		},
		// 拖拽添加需要拷贝源数据，防止由于对象的引用导致元数据被修改
		clone(e) {
			this.operateType = "drag";
			this.$store.commit("updateIsCopy", false);
			let target = JSON.parse(JSON.stringify(all.find(item => item.type === e.type)));
			this.target = target;
			return target;
		},
		// 点击添加任务
		clickAdd(type) {
			this.operateType = "click";
			this.$store.commit("updateIsCopy", false);
			let target = JSON.parse(JSON.stringify(all.find(item => item.type === type)));
			let target_temp = JSON.parse(JSON.stringify(target));
			this.result.push(target_temp);
			this.target = target;
			if (target.config.hasOwnProperty("remark")) {
				this.configDialog_visible = true;
			} else {
				this.result_temp.push(target);
			}
		},
		// 递归 -> 全部任务流程转为一维数组
		flatResult(arr) {
			let result = [];
			for (let i = 0; i < arr.length; i++) {
				result.push(arr[i]);
				let children = arr[i].config.children;
				let other = arr[i].config.other;
				if (children && children.length) {
					result = result.concat(this.flatResult(children));
				}
				if (other && other.length) {
					result = result.concat(this.flatResult(other));
				}
			}
			return result;
		},
		// 递归 -> 获取全部已创建的变量
		getVariables(arr) {
			let variables = [];
			for (let i = 0; i < arr.length; i++) {
				if (arr[i].config.variable) {
					variables.push(arr[i].config.variable);
				}
				let arr1 = arr[i].config.children;
				if (arr1 && arr1.length) {
					variables = variables.concat(this.getVariables(arr1));
				}
			}
			return variables;
		},
		// 配置弹窗关闭
		cancel() {
			if (this.$store.state.editTask) {
				this.$store.commit("updateEditTask", null);
			} else {
				this.result = JSON.parse(JSON.stringify(this.result_temp));
			}
		},
		// 配置弹窗点击确定
		confirmConfig(target) {
			this.configDialog_visible = false;
			if (this.operateType === "click") {
				this.result_temp.push(target);
				this.result = JSON.parse(JSON.stringify(this.result_temp));
			} else if (this.operateType === "drag") {
				this.target.config = {
					...this.target.config,
					...target.config,
				};
				this.result_temp = JSON.parse(JSON.stringify(this.result));
			} else {
				this.target.config = {
					...this.target.config,
					...target.config,
				};
				if (this.$store.state.editTask) {
					this.$store.state.editTask.tasks.splice(this.$store.state.editTask.index, 1, this.target);
					this.$store.commit("updateEditTask", null);
				}
			}
		},
		// 导出任务流程
		exportTask() {
			this.resultJSON = JSON.stringify(this.result, null, 2);
			this.exportDialogVisible = true;
			this.$nextTick(() => {
				this.$refs.jsonViewer.$el.select();
				document.execCommand("copy");
				this.$message.success("流程JSON已复制到粘贴板");
			});
		},
		// 获取分组列表
		getGroupList() {
			groupListApi().then(res => {
				if (res.data.code == 200) {
					this.groupList = res.data.data.list;
				} else {
					this.$message.error(res.data.msg);
				}
			});
		},
		// 添加/更新任务流程
		updateTask() {
			if (!this.taskConfig.name) {
				return this.$message.error("请输入任务名称");
			}
			if (!this.taskAmount) {
				return this.$message.error("请添加任务流程");
			}
			this.updateLoading = true;
			updateTaskApi({
				...this.taskConfig,
				content: JSON.stringify(this.result),
				remark: this.otherSettings.remark,
				config: this.otherSettings,
			})
				.then(res => {
					if (res.data.code == 200) {
						this.$message.success("添加成功");
						this.$router.push("/manage/rpa/manage");
					} else {
						this.$message.error(res.data.msg);
					}
				})
				.finally(() => {
					this.updateLoading = false;
				});
		},
		// 其他设置确认
		otherSettingConfirm(config) {
			this.otherSettings = config;
		},
		// 获取任务详情
		async getTaskDetail(id) {
			let res = await taskDetailApi({ id }).then(res => res.data.data);
			console.log(res);
			let { content, config, ...reset } = res;
			this.result = JSON.parse(content);
			this.taskConfig = reset;
			this.otherSettings = config;
		},
		importTaskJson() {
			try {
				let res = JSON.parse(this.importData);
				// 判断是不是数组、数组中的每一项是不是对象
				if (
					Array.isArray(res) &&
					res.every(item => Object.prototype.toString.call(item) === "[object Object]" && this.operate_name[item.type] && item.config)
				) {
					this.result = res;
					this.$message.success("导入成功");
					this.importDialogVisible = false;
				} else {
					throw new Error();
				}
			} catch (err) {
				return this.$message.error("JSON格式错误");
			}
		},
		async run(config) {
			if (!config) {
				this.selectEnvDialogVisible = true;
			} else {
				await this.sleep(1000);
				const runTask = new RunTask(config.devtoolsurl, this.result);
				await runTask.run();
			}
		},
		sleep(time) {
			return new Promise(r => setTimeout(r, time));
		},
	},
	created() {
		if (this.$route.params.id) {
			this.getTaskDetail(this.$route.params.id);
		}
	},
	mounted() {
		this.getGroupList();
	},
	directives: {
		// v-height -> 计算元素高度
		height: {
			inserted: el => {
				el.style.setProperty("--height", el.scrollHeight + "px");
			},
			update: el => {
				el.style.setProperty("--height", el.scrollHeight + "px");
			},
		},
	},
};
</script>

<style lang="less" scoped>
.rpa-create {
	background-color: #f0f2f5 !important;
	display: flex;
	height: calc(100vh - 90px);
	* {
		user-select: none;
	}
	& > .left {
		flex: 0 0 260px;
		background-color: #fff;
		padding: 20px 6px 20px 16px;
		display: flex;
		flex-direction: column;
		h2 {
			font-size: 18px;
			font-weight: 700;
			margin-bottom: 20px;
		}
		.menu {
			flex: 1;
			overflow-y: auto;
			padding-right: 6px;
			&::-webkit-scrollbar {
				width: 6px;
				background-color: #fff;
			}
			&::-webkit-scrollbar-thumb {
				width: 6px;
				border-radius: 3px;
				background-color: #eee;
			}
			.categoty {
				.title {
					display: flex;
					align-items: center;
					cursor: pointer;
					font-size: 16px;
					color: #333;
					position: relative;
					height: 40px;
					span {
						margin-left: 4px;
					}
					img {
						display: block;
					}
					.icon_arrow {
						position: absolute;
						right: 0;
						transform: rotateZ(-90deg);
						transition: all 0.3s;
					}
				}

				.dragArea {
					transition: all 0.3s;
					height: 0;
					opacity: 0;
					overflow: hidden;
					.operate-draggableItem {
						height: 40px;
						background-color: #f3f3f3;
						border-radius: 4px;
						display: flex;
						align-items: center;
						justify-content: space-between;
						padding: 0 12px;
						margin-bottom: 6px;
						cursor: grab;
						transition: background-color 0.3s;
						svg {
							cursor: pointer;
							color: #999;
							transition: color 0.3s;
						}
						span {
							transition: all 0.3s;
						}

						&:hover {
							color: #3860f4;
							background-color: #ecf2ff;
							svg {
								color: #3860f4;
							}
						}

						&.sortable-chosen {
							color: #3860f4;
							background-color: #ecf2ff;
							cursor: grabbing;
							svg {
								opacity: 0;
							}
						}
					}
				}
				&.active {
					.title {
						.icon_arrow {
							transform: rotateZ(0);
						}
					}
					.dragArea {
						height: var(--height);
						opacity: 1;
					}
				}
			}
		}
	}
	& > .right {
		margin-left: 11px;
		flex: 1;
		background-color: #fff;
		padding: 20px 10px 20px 20px;
		display: flex;
		flex-direction: column;

		.top {
			display: flex;
			align-items: center;
			justify-content: space-between;
			position: relative;
			margin-bottom: 20px;
			h2 {
				font-size: 18px;
				font-weight: 700;
				margin-bottom: 0;
			}
			.right {
				display: flex;
				align-items: center;
				position: absolute;
				right: 0;
				.ant-btn {
					margin-left: 10px;
					color: #3860f4;
					border-color: #3860f4;
					display: flex;
					align-items: center;
					transition: all 0.3s;
					&[disabled] {
						opacity: 0.5;
					}
					svg {
						display: block;
						margin-right: 4px;
						transition: all 0.1s;
					}
					span {
						transition: all 0.1s;
					}
					&:hover:not([disabled]) {
						color: #fff;
						background-color: #3860f4 !important;
					}
				}
			}
		}
		main {
			overflow-y: auto;
			padding-right: 10px;
			display: flex;
			flex-direction: column;
			flex: 1;
			// padding-bottom: 20px;
			&::-webkit-scrollbar {
				width: 6px;
				background-color: #fff;
			}
			&::-webkit-scrollbar-thumb {
				width: 6px;
				border-radius: 3px;
				background-color: #eee;
			}
			.config {
				::v-deep .ant-form {
					display: flex;
					flex-wrap: wrap;
					justify-content: space-between;
					gap: 10px 0;
					.ant-form-item {
						flex: 0 0 49%;
						margin: 0;
						display: flex;
						align-items: center;
						.ant-form-item-label {
							flex: 0 0 80px;
						}
						.ant-form-item-control-wrapper {
							flex: 1;
						}
						&:nth-child(2) {
							.ant-form-item-control-wrapper {
								.ant-form-item-control {
									.ant-form-item-children {
										display: flex;
										align-items: center;
										justify-content: space-between;
										.btn_createGroup {
											flex: 0 0 80px;
											text-align: center;
											color: #3860f4;
											cursor: pointer;
										}
									}
								}
							}
						}
						&:last-child {
							.ant-form-item-control-wrapper {
								.ant-form-item-control {
									.ant-form-item-children {
										display: flex;
										align-items: center;
										justify-content: space-between;
										.ant-select {
											flex: 0 0 49%;
										}
									}
								}
							}
						}
					}
				}
			}
			.other {
				margin-top: 10px;
				display: flex;
				justify-content: flex-end;
				.ant-btn {
					margin-left: 10px;
					color: #3860f4;
					border-color: #3860f4;
					display: flex;
					align-items: center;
					transition: all 0.3s;
					svg {
						display: block;
						margin-right: 4px;
						transition: all 0.1s;
					}
					span {
						transition: all 0.1s;
					}
					&:hover {
						color: #fff;
						background-color: #3860f4 !important;
					}
				}
			}
			.tasks {
				flex: 1;
				margin-top: 20px;
				border: none !important;
				background-color: #f8f8f8;
				border-radius: 6px;
				position: relative;
				.dragArea {
					height: 100%;
				}
				.tip_container {
					position: absolute;
					inset: 0;
					display: flex;
					align-items: center;
					flex-direction: column;
					justify-content: center;
					pointer-events: none;
					overflow: hidden;
					p {
						margin-top: 10px;
						color: #666;
						font-size: 16px;
						font-weight: bold;
						&.tip {
							color: #3860f4;
							font-size: 14px;
							font-weight: normal;
						}
					}
				}
			}
		}
		.footer {
			padding-top: 10px;
			display: flex;
			align-items: center;
			gap: 0 20px;
			.ant-btn:nth-child(1) {
				color: #fff;
				background-color: #3860f4;
				border-color: #3860f4;
				display: flex;
				align-items: center;
				transition: all 0.3s;
				svg {
					display: block;
					margin-right: 4px;
					transition: all 0.1s;
				}
				span {
					transition: all 0.1s;
				}
				&:hover {
					color: #fff;
					background-color: #3860f4 !important;
				}
			}
		}
	}
}
::v-deep .ant-modal {
	.ant-modal-content {
		.ant-modal-title {
			font-weight: bold;
			font-size: 18px;
		}
	}
}
</style>
