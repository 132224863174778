<template>
	<a-modal :visible="visible" @ok="ok" @cancel="cancel" :destroyOnClose="true" :maskClosable="false" title="创建分组" :confirm-loading="loading">
		<a-form-model :model="formData" layout="horizontal" :label-col="{ span: 4 }" :wrapper-col="{ span: 19 }" ref="createGroupForm" :rules="rules">
			<a-form-model-item prop="name" label="分组名称">
				<a-input placeholder="请输入分组名称" v-model="formData.name" :maxLength="30">
					<span slot="suffix">{{ formData.name.length }}/30</span>
				</a-input>
			</a-form-model-item>
		</a-form-model>
	</a-modal>
</template>

<script>
import { groupUpdateApi } from "@/api/rpa";
export default {
	props: {
		visible: {
			type: Boolean,
			default: false,
		},
	},
	components: {},
	name: "CreateGroup",
	data() {
		return {
			formData: {
				name: "",
			},
			loading: false,
			rules: {
				name: [{ required: true, message: "请输入分组名称", trigger: "blur" }],
			},
		};
	},
	methods: {
		ok() {
			this.$refs.createGroupForm.validate(result => {
				if (result) {
					this.loading = true;
					groupUpdateApi({ name: this.formData.name })
						.then(res => {
							if (res.data.code == 200) {
								this.$message.success("创建成功");
								this.$emit("success", false);
								this.$emit("update:visible", false);
							}
						})
						.finally(() => {
							this.loading = false;
						});
				}
			});
		},
		cancel() {
			this.$emit("update:visible", false);
		},
	},
};
</script>
<style lang="scss" scoped></style>
